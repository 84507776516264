import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@uk';

import { IUploadResult2 } from '../../types';

import { DialogStore } from './dialog.store';
import { DialogBody } from './dialogBody';

import { Z_INDEX_COMMON_DIALOG } from '@/common/constants';

export interface IDialog {
  isOpen: boolean;
  closeDialog: (status: ECommonDialogCloseStatus) => void;
  initValues?: IUploadResult2;
}

export const DialogCreate = observer(({ isOpen, closeDialog, initValues }: IDialog) => {
  const { t } = useTranslation();
  const [uiStore] = useState(() => new CommonDialogUiStore());
  const [dialogResult] = useState(() => new DialogStore(initValues));

  useEffect(() => {
    return autorun(() => {
      uiStore.setOkEnabled(dialogResult.ready);
    });
  }, [dialogResult.ready, uiStore]);

  const handleClose = useCallback(
    async (status: ECommonDialogCloseStatus) => {
      if (status === ECommonDialogCloseStatus.OK) {
        runInAction(() => {
          uiStore.setOkEnabled(false);
          uiStore.setPending(true);
        });
        const success = await dialogResult.toServerViaRedis();
        if (!success) {
          runInAction(() => {
            uiStore.setOkEnabled(true);
            uiStore.setPending(false);
          });

          return;
        }
      }
      closeDialog(status);
      dialogResult.setInitValues();
      uiStore.setPending(false);
    },
    [closeDialog, dialogResult, uiStore],
  );

  useEffect(() => {
    toast.info(t('uploadInfo'));
  }, [t]);

  return (
    <CommonDialog
      bodyProps={{ padding: '0 px', maxHeight: '70vh' }}
      fullheightWithoutScroll
      topPanel2={'10px'}
      style={{ zIndex: Z_INDEX_COMMON_DIALOG, position: 'absolute' }}
      title={'uploadFiles'}
      open={isOpen}
      onClose={handleClose}
      contentComponent={<DialogBody data={dialogResult} />}
      autotestId={'upload2'}
      uiStore={uiStore}
    />
  );
});
