import { ClickAwayListener, makeStyles, MenuItem, MenuList, Popper, Tooltip, Typography } from '@material-ui/core';
import { History, MoreVert } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { EDirection } from '../languages/i-language';
import { ERecordStatus } from '../records/types';

import { TranscriptTranslateToggle } from '../transcriptTranslateToggleButton/TranscriptTranslateToggle';

import { gAPP_STORE } from '@/app/app-store';
import { IconResummarize, IconRetranslate } from '@/components/summary/AIicons';
import { IToolbarExDescriptorItem, ToolbarEx } from '@/react-ui-kit/src/components/toolbarEx';

const styleForButton = makeStyles(() => ({
  iconButtonSquarePants: {
    width: '40px',
    height: '40px',
    alignItems: 'left',
    backgroundColor: 'white',
    border: '1px solid rgba(108, 115, 127, 0.12)',
    boxSizing: 'border-box',
    //lineHeight: '1.75px',
    borderRadius: '8px',
  },
}));

interface ISummaryTopPanel {
  text?: string;
}

export const SummaryTopPanel = observer(({ text }: ISummaryTopPanel) => {
  const summaryStore = gAPP_STORE.summaryStore;
  const {
    currentSummaryRtl: rtl,
    showHistory,
    setShowHistory,
    isTranslate,
    setTranslateMode,
    summaryStatus,
    summaryTranslationStatus,
    retranslate,
    resummarize,
  } = summaryStore;
  const { t } = useTranslation();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const classes = styleForButton();

  const blockTitle = useMemo(
    () => (
      <div
        data-autotest={'summary-title'}
        style={{
          width: '100%',
          maxHeight: '38px',
          whiteSpace: 'text-wrap',
          wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          backgroundColor: 'white',
          padding: 0,
          margin: 0,
          direction: rtl,
        }}
      >
        <Tooltip title={text ?? ''} style={{ backgroundColor: 'white', direction: rtl }}>
          <Typography style={{ fontSize: '1em', lineHeight: '1.1em', fontWeight: '600' }}>{text}</Typography>
        </Tooltip>
      </div>
    ),
    [rtl, text],
  );

  const styleMenuItem = useCallback(
    (isActive: boolean) => ({
      padding: '10px 16px',
      gap: '16px',
      alignItems: 'top',
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif',
      color: `${isActive ? 'rgba(11, 43, 72, 1)' : 'rgba(0, 0, 0, 0.56)'}`,
      fontWeight: 400,
      LineHeight: '19.36px',
    }),
    [],
  );

  const resummarizeEnable = useMemo(
    () => summaryStatus === ERecordStatus.PROCESSED || summaryStatus === ERecordStatus.FAILED,
    [summaryStatus],
  );
  const retranslateEnable = useMemo(
    () =>
      summaryStatus === ERecordStatus.PROCESSED &&
      (!summaryTranslationStatus || [ERecordStatus.PROCESSED, ERecordStatus.FAILED].includes(summaryTranslationStatus)),
    [summaryStatus, summaryTranslationStatus],
  );

  const toolbarExProps = useMemo((): IToolbarExDescriptorItem[] => {
    const toolbarDescriptor: IToolbarExDescriptorItem[] = [];

    if (rtl === EDirection.RTL) {
      toolbarDescriptor.push({
        type: 'filler',
      });
    }

    toolbarDescriptor.push({
      type: 'custom',
      component: blockTitle,
    });

    if (rtl === EDirection.LTR) {
      toolbarDescriptor.push({
        type: 'filler',
      });
    }

    toolbarDescriptor.push(
      {
        tooltip: 'summary.retranslate',
        type: 'custom',
        component: (
          <button
            data-autotest={'summary-button-showMenu'}
            ref={anchorRef}
            className={classes.iconButtonSquarePants}
            onClick={() => setShowMenu(prev => !prev)}
            style={{ cursor: 'pointer' }}
          >
            <MoreVert />
          </button>
        ),
        pl: 1,
      },
      {
        tooltip: 'summary.retranslate',
        type: 'custom',
        component: <TranscriptTranslateToggle isTranslate={isTranslate} onChange={setTranslateMode} />,
        pl: 1,
      },
      {
        tooltip: 'summary.history',
        type: 'toggle-button',
        icon: <History />,
        selected: showHistory,
        setSelected: setShowHistory,
        pl: 1,
        pr: 0,
      },
    );

    return toolbarDescriptor;
  }, [blockTitle, classes.iconButtonSquarePants, isTranslate, rtl, setShowHistory, setTranslateMode, showHistory]);

  const menuRepeat = useMemo(
    () => (
      <Popper
        anchorEl={anchorRef.current}
        style={{
          zIndex: 1200,
        }}
        open={showMenu}
      >
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <MenuList
            data-autotest={'summary-Menu-Repeat'}
            style={{
              //width: '243px',
              borderRadius: '4px',
              padding: '8px 0',
              backgroundColor: '#fff',
              boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
            }}
          >
            <MenuItem
              data-autotest={'summary-Menu-Repeat-Summary'}
              key={'menu-repeat-summary'}
              onClick={() => {
                handleCloseMenu();
                toast.info(t('summary.toastRepeatSummary'));
                resummarize();
              }}
              style={styleMenuItem(resummarizeEnable)}
              disabled={!resummarizeEnable}
            >
              <>
                <IconResummarize disabled={!resummarizeEnable} />
                {`  ${t('summary.menuRepeatSummary')}`}
              </>
            </MenuItem>
            <MenuItem
              data-autotest={'summary-Menu-Repeat-Translate'}
              key={'menu-repeat-translate'}
              onClick={() => {
                handleCloseMenu();
                toast.info(t('summary.toastRepeatTranslate'));
                retranslate();
              }}
              style={styleMenuItem(retranslateEnable)}
              disabled={!retranslateEnable}
            >
              <>
                <IconRetranslate disabled={!retranslateEnable} />
                {`  ${t('summary.menuRepeatTranslate')}`}
              </>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    ),
    [resummarize, resummarizeEnable, retranslate, retranslateEnable, showMenu, styleMenuItem, t],
  );

  return (
    <div
      data-autotest={'TopSummaryPanel'}
      id="SummaryTopPanel"
      style={{
        flexShrink: 0,
        height: '56px',
        borderBottom: '1px solid rgba(221, 221, 221, 1)',
        boxSizing: 'border-box',
        padding: '7px 0px 0px 0px',
        backgroundColor: 'white',
        minWidth: '300px',
        width: '100%',
      }}
    >
      <ToolbarEx
        style={{ flexGrow: `${rtl === EDirection.RTL ? '1!important' : 'start'}` }}
        descriptor={toolbarExProps}
        noBorder
      />
      {menuRepeat}
    </div>
  );
});
