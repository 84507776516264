import { BackendError, IBackendErrorInfo } from './types';
import { backendApiUrl } from './constants';

import { gAPP_STORE } from '@/app/app-store';
/* eslint-disable @typescript-eslint/no-explicit-any */

async function goodReply(response: Response, empty: null | '') {
  const text = await response.text();
  if (text.length > 0) {
    try {
      return JSON.parse(text);
    } catch (e) {
      return text;
    }
  }

  return empty;
}

async function badReply(response: Response, isRedirect = true): Promise<BackendError> {
  if (response.status === 403 && isRedirect) {
    gAPP_STORE.setStatusCodeError(403);
    localStorage.removeItem('userSession');

    return new BackendError('Unauthorized', 401, {
      description: 'Unauthorized',
      stackTrace: 'Unauthorized',
      reasonCode: '###Unauthorized',
    });
  }

  if (response.status === 401 && isRedirect) {
    gAPP_STORE.setStatusCodeError(401);
    localStorage.removeItem('userSession');
  }

  let responseJson;
  try {
    responseJson = await response.json();
  } catch (ex) {}
  if (!responseJson) {
    try {
      responseJson = await response.text();
    } catch (ex) {}
  }

  return new BackendError(response.statusText, response.status, responseJson as IBackendErrorInfo);
}

class CBackendService {
  /**
   * GET request
   *
   * @param controller
   * @param urlParams
   * @returns
   */
  get = async (
    controller: string,
    urlParams?: string,
    noAuth?: boolean,
    noPrefix?: boolean,
    sessionId?: string,
    isRedirect?: boolean,
    textContent?: boolean,
  ) => {
    const headers = new Headers();
    headers.append('accept', 'application/json;charset=UTF-8');
    headers.append('X-Requested-With', 'XMLHttpRequest'); //prevent browser auth POPup
    if (noAuth) {
      headers.append('authorization', sessionId ?? gAPP_STORE.loginStore.getAuthorizationString());
    }

    if (textContent) {
      headers.append('Content-Type', 'text/plain');
      headers.append('accept', 'text/plain');
    }

    const options = { method: 'GET', headers };
    let url = noPrefix === true ? '' : backendApiUrl;
    if (controller && controller.length > 0) {
      url += `/${controller}`;
    }
    if (urlParams && urlParams.length > 0) {
      url += `?${urlParams}`;
    }
    try {
      const request = new Request(url, options);

      const response = await fetch(request);

      const { status, ok } = response;

      if (ok && [200, 201, 204].includes(status)) {
        return goodReply(response, null);
      }

      throw await badReply(response, isRedirect);
    } catch (e) {
      throw e;
    }
  };

  /**
   * POST request
   *
   * @param controller
   * @param body
   * @returns
   */
  post = async (
    controller: string,
    body: any,
    uploadRecord = false,
    textContent = false,
    noPrefix?: boolean,
    sessionId?: string,
    jwt?: undefined,
    isRedirect?: boolean,
  ) => {
    return await this.postPut(
      'POST',
      controller,
      body,
      uploadRecord,
      textContent,
      noPrefix,
      sessionId,
      jwt,
      isRedirect,
    );
  };

  /**
   * PUT
   *
   * @param controller
   * @param body
   * @returns
   */
  put = async (controller: string, body: any, uploadRecord = false, textContent = false) => {
    return await this.postPut('PUT', controller, body, uploadRecord, textContent);
  };

  /**
   * DELETE
   *
   * @param controller
   * @param id
   * @returns
   */
  delete = async (controller: string, body?: any) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-Requested-With', 'XMLHttpRequest'); //prevent browser auth POPup

    const options = { method: 'DELETE', headers, body };
    let url = backendApiUrl;
    if (controller && controller.length > 0) {
      url += `/${controller}`;
    }

    try {
      const request = new Request(url, options);

      const response = await fetch(request);

      const { status, ok } = response;

      if (ok && status >= 200 && status < 300) {
        return goodReply(response, null);
      }

      throw await badReply(response);
    } catch (e) {
      throw e;
    }
  };

  /**
   * POST | PUT
   *
   * @param method
   * @param controller
   * @param body
   * @returns
   */
  postPut = async (
    method: string,
    controller: string,
    body: any,
    uploadRecord = false,
    textContent?: boolean,
    noPrefix?: boolean,
    sessionId?: string,
    jwt = undefined,
    isRedirect?: boolean,
  ) => {
    const headers = new Headers();
    headers.append('X-Requested-With', 'XMLHttpRequest');
    if (textContent) {
      headers.append('Content-Type', 'text/plain');
      headers.append('accept', 'text/plain');
    } else if (uploadRecord) {
      headers.append('Content-Type', 'application/octet-stream');
    } else {
      headers.append('Content-Type', 'application/json');
    }

    headers.append('accept', 'application/json;charset=UTF-8');

    if (sessionId?.length) {
      headers.append('authorization', sessionId);
    }

    if (jwt) {
      headers.append('authorization', jwt);
    }

    const options = { method, headers, body };
    let url = noPrefix === true ? '' : backendApiUrl;
    if (controller && controller.length > 0) {
      url += `/${controller}`;
    }

    try {
      const request = new Request(url, options);

      const response = await fetch(request);

      const { status, ok } = response;

      if (ok && [200, 201, 204].includes(status)) {
        return goodReply(response, null);
      }

      throw await badReply(response, isRedirect);
    } catch (e) {
      throw e;
    }
  };
}

export const BackendService = new CBackendService();
