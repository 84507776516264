import { Box } from '@material-ui/core';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { SizeMeProps, withSize } from 'react-sizeme';

import { observer } from 'mobx-react';

import { IToolbarExDescriptorItem, ToolbarEx } from '@uk';
//import { useWavesurfer } from 'wavesurfer-react';
import { WaveSurfer } from 'wavesurfer-react';

import { reaction } from 'mobx';

import PlayerDictorPanel from '../player-with-dictors/PlayerDictorPanel';

import PlayerErrorMsg from './PlayerErrorMsg';
import PlayerLoadingPanel from './PlayerLoadingPanel';
import PlayerRecordInfoPanel from './PlayerRecordInfoPanel';

import { PLAYER_HEIGHT, PlayerStore, RESIZE_DELAY, TIMELINE_HEIGHT } from './Player.store';

import { waveFormProps } from './waveformStyle';

import { PlayerChannelBar } from './PlayerChannelBar';

import { gAPP_STORE } from '@/app/app-store';
import { useDragScroll } from '@/hooks/useDragScroll';

const withSizeHOC = withSize({
  monitorWidth: true,
  monitorHeight: false,
  noPlaceholder: true,
  refreshMode: 'debounce',
  refreshRate: RESIZE_DELAY,
});

export interface IPlayerProps extends SizeMeProps {
  id: string;
  infoPanel?: ReactNode;
}

const Player = withSizeHOC(
  observer((props: IPlayerProps) => {
    const { size, id, infoPanel: externalInfoPanel } = props;

    const playerStore = useMemo(() => new PlayerStore(id), [id]);
    const {
      loading,
      loaded,
      replaceRecordAndLoadPeaks,
      wsReady,
      isErrorPlayer,
      showRecordInfo,
      handleWaveSurferMount,
      toggleShowRecordInfo,
      handlePlayPause,
      addMarkers,
    } = playerStore;

    const { soundStore, videoStore, summaryStore, getRecordTextStore, getDictorsStore, getFilterStore } = gAPP_STORE;
    const { record, isPlaying, isCycling } = soundStore;
    const { showPlayer, toggleShowPlayer } = videoStore;
    const dictorsStore = getDictorsStore();
    const { currentWordsData } = getRecordTextStore();

    useEffect(() => {
      return reaction(
        () => summaryStore.isShowSummrySegmentation(),
        (flag: boolean) => playerStore.setShowSummrySegmentation(flag),
      );
    }, [playerStore, summaryStore]);

    useEffect(() => {
      return reaction(
        () => soundStore.record,
        () => replaceRecordAndLoadPeaks(soundStore.record),
      );
    }, [playerStore, replaceRecordAndLoadPeaks, soundStore]);

    useEffect(() => {
      return reaction(
        () => dictorsStore.hideSegmentation,
        () => !summaryStore.currentTimecodeUuid && playerStore.showDictorsSegmentation(),
      );
    }, [dictorsStore, playerStore, summaryStore.currentTimecodeUuid]);

    useEffect(() => {
      return reaction(
        () => summaryStore.visibleTimecodes,
        (arg, prev) => {
          if (prev.length !== 0 && arg.length === 0) {
            playerStore.showDictorsSegmentation();
          } else {
            if (arg.length !== 0) {
              playerStore.showSummarySegmentation();
            } else {
              console.log('reaction visibleTimecodes is again empty');
            }
          }
        },
      );
    }, [playerStore, summaryStore.visibleTimecodes]);

    useEffect(() => {
      return reaction(
        () => getFilterStore().Data,
        () => playerStore.addMarkers(currentWordsData),
      );
    }, [currentWordsData, getFilterStore, getRecordTextStore, playerStore]);

    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollRef] = useDragScroll();

    useEffect(() => {
      if (playerStore.ws) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scrollRef((playerStore.ws as any).renderer.scrollContainer);
      }
    }, [playerStore.ws, scrollRef]);

    useEffect(() => {
      if (wsReady && !isErrorPlayer) {
        addMarkers(currentWordsData);
      }
    }, [addMarkers, currentWordsData, isErrorPlayer, wsReady]);

    // useEffect(() => {
    //   if (wsReady && !isErrorPlayer) {
    //     addDictorsRegions(dictorsToShowSegmentation);
    //   }
    // }, [addDictorsRegions, dictorsToShowSegmentation, isErrorPlayer, wsReady]);

    const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
      (): IToolbarExDescriptorItem[] => [
        {
          type: 'button',
          text: isPlaying ? 'player.stop' : 'player.play',
          icon: isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />,
          disabled: loading,
          onClick: handlePlayPause,
        },
        { type: 'filler' },
        {
          type: 'switcher',
          text: 'Show info',
          value: showRecordInfo,
          disabled: loading,
          onChange: toggleShowRecordInfo,
        },
        {
          type: 'switcher',
          text: 'Show video',
          value: showPlayer,
          disabled: loading || !record?.__videoStorageKey || isCycling,
          onChange: toggleShowPlayer,
          pl: 3,
        },
      ],
      [
        isPlaying,
        loading,
        handlePlayPause,
        showRecordInfo,
        toggleShowRecordInfo,
        showPlayer,
        record?.__videoStorageKey,
        isCycling,
        toggleShowPlayer,
      ],
    );

    const showRecordInfoPanel = wsReady && !isErrorPlayer && showRecordInfo;
    const showLoadingPanel = loading && !isErrorPlayer;
    const showErrorPanel = isErrorPlayer;
    const showExternalInfoPanel = !loading && !isErrorPlayer;

    return (
      <Box display="flex" flexGrow={1} flexDirection="column" position={'relative'}>
        <Box display="flex" flexGrow={1} flexDirection="column" style={{ position: 'relative' }}>
          {showRecordInfoPanel && <PlayerRecordInfoPanel />}
          <PlayerChannelBar store={playerStore} />
          <Box
            id="playerRoot"
            style={{
              minHeight: PLAYER_HEIGHT + TIMELINE_HEIGHT,
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              position: 'relative',
              backgroundImage: 'url("/playerBack.png")',
              backgroundPosition: 'right -5px',
            }}
          >
            <PlayerDictorPanel avatarCache={dictorsStore.cacheDictorAvatars} />
            {showLoadingPanel && <PlayerLoadingPanel title={'soundLoading'} loaded={loaded} />}
            {showErrorPanel && <PlayerErrorMsg />}
            <WaveSurfer onMount={handleWaveSurferMount} container={containerRef.current} {...waveFormProps}>
              <div
                id={`WaveForm_${id}`}
                ref={containerRef}
                style={{ width: `${size.width}px`, maxWidth: `${size.width}px`, minWidth: `${size.width}px` }}
              />
            </WaveSurfer>
          </Box>
          {showExternalInfoPanel && externalInfoPanel}

          {<ToolbarEx descriptor={toolbarDescriptor} />}
        </Box>
      </Box>
    );
  }),
);

export default Player;
