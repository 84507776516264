import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroup, Box, Typography } from '@material-ui/core';
import { CommonDialog, CommonDialogUiStore, LoadingPanel } from '@uk';

import { IGroup } from './types';
import { IDeleteDialogResult } from './group-dialog';

import { gAPP_STORE } from '@/app/app-store';

interface IDialogDeleteGroupProps {
  group?: IGroup;
  onClose: (dialogResult: IDeleteDialogResult) => void;
}

const DialogDeleteGroup: React.FC<IDialogDeleteGroupProps> = ({ group, onClose }) => {
  const { t } = useTranslation();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());
  const [recordCountWithGroup, setRecordCountWithGroup] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (group) {
      gAPP_STORE
        .getGroupsStore()
        .getRecordCountWithGroup(group.id)
        .then(value => setRecordCountWithGroup(value));
    } else {
      setRecordCountWithGroup(0);
    }
  }, [group]);

  const handleClose = (status: number) => {
    onClose({ closeStatus: status });
  };

  const DialogBody = () => {
    return (
      <Box width={600}>
        {recordCountWithGroup !== undefined ? (
          <FormGroup>
            <>
              {recordCountWithGroup > 0 && (
                <Typography style={{ lineHeight: 1.5 }}>{`${t('groups.deleteConfirm2')}`}</Typography>
              )}
              <Typography style={{ lineHeight: 1.1 }}>{`${t('groups.deleteConfirm')}`}</Typography>
              <Typography style={{ padding: '30px' }}>{group?.name}</Typography>
            </>
          </FormGroup>
        ) : (
          <LoadingPanel />
        )}
      </Box>
    );
  };

  return (
    <CommonDialog
      title={t('group')}
      open={true}
      onClose={handleClose}
      contentComponent={DialogBody}
      autotestId={'groups'}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default DialogDeleteGroup;
