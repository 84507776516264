import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EDirection } from '../languages/i-language';

import { CollapsePanel } from './common/CollapsePanel';
import { RolesPanel } from './RolesPanel';
import { TaskPanel } from './TaskPanel';
import { ETopicStatus, ITopic } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { Timecodes } from '@/components/summary/Timecodes';

export interface ITopicCollapsePanel {
  indTopic: number;
  topic: ITopic;
}

export const TopicCollapsePanel = observer(({ indTopic, topic }: ITopicCollapsePanel) => {
  const { t } = useTranslation();
  const summaryStore = gAPP_STORE.summaryStore;
  const rtl = summaryStore.currentSummaryRtl;
  const mentions = summaryStore.getTopicMentions(indTopic);
  const autotest = `TopicCollapsePanel-${indTopic}`;

  const { description, participants, status, tasks } = topic;

  const compBody = useMemo(
    () => (
      <>
        {topic?.status === ETopicStatus.loading && (
          <div
            data-autotest={`${autotest}-topicLoading`}
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('summary.topicLoading')}
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'unset', padding: '12px', gap: '8px' }}>
          <div
            data-autotest={`${autotest}-description`}
            style={{ width: '100%', direction: rtl, textAlign: `${rtl === EDirection.RTL ? 'right' : 'left'}` }}
          >
            <span style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 400 }}>{description || '--'}</span>
          </div>
          <div data-autotest={`${autotest}-speakers`} style={{ width: '100%' }}>
            <span
              style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 400, color: 'rgba(108, 115, 127, 1)' }}
            >{`${t('summary.speakers')}:`}</span>
          </div>
          <RolesPanel
            id={`topic-${indTopic}`}
            participants={participants ?? []}
            mentions={mentions}
            canEdit={false}
            autotest={autotest}
          />
          <div style={{ width: '100%' }}>
            <span
              style={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 400, color: 'rgba(108, 115, 127, 1)' }}
            >{`${t('summary.taskList')}:`}</span>
          </div>
          {tasks?.map((task, indTask) => (
            <TaskPanel
              rtl={rtl}
              id={`${indTopic}-${indTask}`}
              indTask={indTask}
              task={task}
              topic={topic}
              autotest={`${autotest}-task-${indTask}`}
            />
          ))}
        </div>
      </>
    ),
    [autotest, rtl, description, t, indTopic, participants, mentions, tasks, topic],
  );

  return (
    <CollapsePanel
      autotest={`${autotest}`}
      id={autotest}
      title={<div style={{ fontFamily: 'Plus Jakarta Sans', fontWeight: 700 }}>{topic.title || '--'}</div>}
      rtl={rtl}
      button={
        <Timecodes
          timecodesItem={topic.timecodesItem}
          onShowOnPlayer={() => console.log('click on counter')}
          onClickTimeCode={t => console.log('click on time code: ', t)}
          autotest={autotest}
        />
      }
      showPanel={summaryStore.isTopicShow(indTopic)}
      switchShow={() => summaryStore.switchTopicsShow(indTopic)}
      bgcolor={'rgba(241, 242, 244, 1)'}
      component={compBody}
    />
  );
});
