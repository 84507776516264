import { IDataSourceEntity } from './types';

import { IDataGridCol, TDataGridRenderer } from '@/react-ui-kit/src';

const spaceIdRenderer: TDataGridRenderer = (row: IDataSourceEntity) => {
  return row.settings.spaceObjectId;
};

const ciscoNamespaceRenderer: TDataGridRenderer = (row: IDataSourceEntity) => {
  return row.settings.ciscoNamespace;
};

export const sourcesGridColumns: IDataGridCol<IDataSourceEntity>[] = [
  { id: 'name', name: 'dataSources.channelName', width: 60, noSort: true },
  {
    id: 'ciscoNamespace',
    name: 'dataSources.ciscoName',
    width: 200,
    renderer: ciscoNamespaceRenderer,
    noSort: true,
  },
  {
    id: 'spaceId',
    name: 'dataSources.spaceId',
    width: 300,
    renderer: spaceIdRenderer,
    noSort: true,
  },
];
