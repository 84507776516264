import { computed, observable, action, transaction } from 'mobx';
import flat from 'flat';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import i18next from 'i18next';

import { TLangCode, TTranslationSource } from './types';
import { AVAILABLE_LANGS, DEFAULT_LANG } from './constants';

import { gAPP_STORE } from '@/app/app-store';

export class CTranslationStore {
  private _urlLang?: string;
  constructor(langFromUrl?: string) {
    if (langFromUrl && AVAILABLE_LANGS.includes(langFromUrl)) {
      this._urlLang = langFromUrl;
    }
  }

  @observable locale: TLangCode = DEFAULT_LANG;
  @observable isLoading = false;

  @observable.ref messages: any = {};

  @action setLoading(state: boolean): void {
    this.isLoading = state;
  }

  @action setLocale(locale: TLangCode): void {
    this.locale = locale;
  }

  @action setMessages(messages: TTranslationSource): void {
    this.messages = mapValues<{ [key: string]: unknown }, string>(flat(messages), (value: unknown) => String(value));
  }

  @computed get translate(): (path: string, defaultValue?: string, values?: Record<string, string | number>) => string {
    // subscribe to messages
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // this.messages;

    return (path, defaultMessage = '', values): string => {
      if (!get(this.messages, path)) return defaultMessage;

      return this.messages[path];
    };
  }

  async switchLanguage(langCode: TLangCode): Promise<void> {
    if (i18next.services.languageUtils) {
      i18next.changeLanguage(langCode);
      localStorage.setItem('language', langCode);
    }

    this.setLoading(true);

    const [translation, lang] = await Promise.all([
      import(`i18n/${langCode}.json`),
      import(`i18n/${langCode}/lang.json`),
    ]);

    const messages = {
      ...translation.default,
      lang: { ...lang.default },
    };

    transaction(() => {
      this.setMessages(messages);
      this.setLocale(langCode);

      this.setLoading(false);
    });
  }

  @action async init(): Promise<void> {
    if (this._urlLang) {
      await this.switchLanguage(this._urlLang as TLangCode);
    } else {
      let lang = localStorage.getItem('language');

      if (!lang || !gAPP_STORE.availableLocales.includes(lang as TLangCode)) {
        lang = gAPP_STORE.availableLocales[0];
      }

      await this.switchLanguage(lang as TLangCode);
    }
  }
}
