import { BackendService } from '../backend-service';

import { IChannel } from '@/types/channels';

class _ChannelsService {
  async getAll() {
    try {
      const data: IChannel[] = await BackendService.get('channels/all');

      return data;
    } catch (err) {
      console.error(err);
    }
  }
}
export const ChannelsService = new _ChannelsService();
