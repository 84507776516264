import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { IDataGridCol, TDataGridRenderer } from '@uk';

import { UNKNOWN_SPEAKER } from '../dictors/const';

import { EEventType, IEventLogEntry } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { strToDatetimeStr } from '@/react-ui-kit/src/utils/dateUtils';

const EventType: React.FC<{ eventType: number }> = observer(({ eventType }) => {
  const { t } = useTranslation();

  return <>{t(eventType === EEventType.action ? 'eventType.action' : 'eventType.error')}</>;
});

const ActionType: React.FC<{ actionType: number }> = observer(({ actionType }) => {
  const { t } = useTranslation();
  const getActionDescription = useCallback(
    (actionId: number) => `actionType.${gAPP_STORE.getEventLogStore().getEventDescription(actionId)}`,
    [],
  );

  return <>{t(getActionDescription(actionType) ?? '')}</>;
});

const ActionMessage: React.FC<{ actionType: number; actionParameters: string }> = observer(
  ({ actionType, actionParameters }) => {
    const { t } = useTranslation();
    const params = JSON.parse(actionParameters);

    const getActionDescription = useCallback((actionId: number) => {
      return `actionMessage.${gAPP_STORE.getEventLogStore().getEventDescription(actionId)}`;
    }, []);

    let newActionParameters: { [key: string]: string } = { ...params };

    if (actionType === 11 || actionType === 12 || actionType === 13) {
      const action =
        params['is_auto_asr'] && params['is_kws']
          ? t('eventLog.is_auto_asr_and_kws')
          : params['is_auto_asr']
          ? t('eventLog.is_auto_asr')
          : params['is_kws']
          ? t('eventLog.is_kws')
          : '';

      newActionParameters = { ...newActionParameters, action1: action };
    }

    if (actionType === 36) {
      const processingMethod = t(`processing_method.${params['processing_method']}`);

      newActionParameters = { ...newActionParameters, processing_method: processingMethod };
    }

    const dictorName = newActionParameters['dictor_name'];

    if (dictorName) {
      let newDictorNameSpeaker = `${dictorName.split(' ')[0] === '#Speaker#' ? t(UNKNOWN_SPEAKER) : ''}`;

      if (newDictorNameSpeaker !== '') {
        newDictorNameSpeaker = `${newDictorNameSpeaker} ${parseInt(dictorName.replace(/[^\d]/g, '')) - 100}`;
        newActionParameters['dictor_name'] = newDictorNameSpeaker;
      }
    }

    newActionParameters['meeting_title_column_name'] =
      gAPP_STORE.meetingTitleColumnName === undefined
        ? t('records.meetingTitle')
        : (gAPP_STORE.meetingTitleColumnName as string);

    return <>{t(`${getActionDescription(actionType)}`, newActionParameters)}</>;
  },
);

const userRenderer: TDataGridRenderer = (row: IEventLogEntry) => {
  const userId = row.userId ? +row.userId : -1;

  return userId > -1 ? gAPP_STORE.getUsersStore().data.users.find(user => user.id === userId)?.email || '' : '';
};

const IpAddressRenderer: TDataGridRenderer = (row: IEventLogEntry) => {
  return row.ip ?? '--';
};

const eventRenderer: TDataGridRenderer = (row: IEventLogEntry) => {
  return <EventType eventType={row.eventType} />;
};

const actionRenderer: TDataGridRenderer = (row: IEventLogEntry) => {
  return <ActionType actionType={row.actionType} />;
};

const messageRenderer: TDataGridRenderer = (row: IEventLogEntry) => {
  return <ActionMessage actionType={row.actionType} actionParameters={row.actionParameters} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateRenderer: TDataGridRenderer = (row: any[], value: any) => {
  return strToDatetimeStr(value);
};

export const eventLogGridDescriptor: IDataGridCol<IEventLogEntry>[] = [
  {
    id: 'eventType',
    name: 'eventLog.eventType',
    width: 30,
    align: 'left',
    renderer: eventRenderer,
    noSort: true,
  },
  {
    id: 'actionType',
    name: 'eventLog.actionType',
    width: 80,
    align: 'left',
    renderer: actionRenderer,
    noSort: true,
  },
  {
    id: 'message',
    name: 'eventLog.actionMessage',
    align: 'left',
    renderer: messageRenderer,
    noSort: true,
    isOverflow: true,
  },
  {
    id: 'loggedDatetime',
    name: 'eventLog.loggedDatetime',
    width: 80,
    renderer: dateRenderer,
    align: 'left',
    noSort: false,
  },
  { id: 'user', name: 'eventLog.userId', width: 150, renderer: userRenderer, noSort: true },
  { id: 'IpAddress', name: 'eventLog.ip', width: 150, renderer: IpAddressRenderer, noSort: true },
];
