import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import clsx from 'clsx';

import { alpha, Box, ClickAwayListener, Typography } from '@material-ui/core';

import { useStyles } from '../record-text';
import { ERecordStatus, IPhrase, IWord, statusToModelStatus } from '../types';

import { PhraseToken } from './phrase-token';

import { PhraseEdited } from './phrase-edited';

import { IChangeDictorInfo } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { EDirection } from '@/components/languages/i-language';
import { DictorChip } from '@/components/dictors';
import { IDictor } from '@/types/dictors';
import { useIndexedSelection } from '@/hooks/useIndexedSelection';
import { ImageCacheType } from '@/components/avatar/cached-avatar';

export const isSelectPossible = () => {
  const { record, isTranslation } = gAPP_STORE.getRecordTextStore();
  if (!record) {
    return false;
  }
  const permitEdit = (gAPP_STORE.loginStore.user?.isEditor ?? false) && gAPP_STORE.editingASR;

  return (
    permitEdit && !isTranslation && statusToModelStatus(record.voiceModelStatus) !== ERecordStatus.MODEL_REBUILDING
  );
};

export interface IPhraseProps {
  phrases: IPhrase[];
  phraseIndex: number;
  wordProbability: number;
  stereo: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, phraseIndex: number) => void;
  handleEditableFocused: (phraseIndex: number) => void;
  handleEditComplete: (
    event: React.ChangeEvent<HTMLDivElement>,
    phraseIndex: number,
    changeDictor?: IChangeDictorInfo,
  ) => void;
  handleEditKeyDown: (event: React.KeyboardEvent, phraseIndex: number) => void;
  onCutBubble: (phraseIndex: number, selectFrom: number, selectUpto: number, newDictor: IDictor) => void;
  avatarCache?: ImageCacheType;
}

type TPhraseNode = JSX.Element | string;

export const getTokenId = (phraseIndex: number, tokenIndex: number) => `${phraseIndex}=${tokenIndex}`;

export const Phrase: React.FC<IPhraseProps> = observer(props => {
  const {
    phrases,
    phraseIndex,
    wordProbability,
    stereo,
    onClick,
    handleEditableFocused,
    handleEditComplete,
    handleEditKeyDown,
    onCutBubble,
    avatarCache,
  } = props;
  const phrase = useMemo(() => phrases[phraseIndex], [phraseIndex, phrases]);

  const thisRef = useRef<HTMLDivElement>(null);

  const recordTextStore = gAPP_STORE.getRecordTextStore();
  const { record, isTranslation } = recordTextStore;
  const { hidePhrases } = gAPP_STORE.getDictorsStore();
  const classes = useStyles();
  const [hideDictor, setHideDictor] = useState(false);

  const { selectionsStore, onSelectionMouseDown, onSelectionEnd, selectionOver, clear } = useIndexedSelection(
    phraseIndex,
    phrase.tokens,
    thisRef,
  );

  const phraseDirection = useMemo((): EDirection => {
    const recordLanguage =
      isTranslation && record?.translateLanguage ? record?.translateLanguage : record?.languageResolved;
    const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);

    return isRTL ? EDirection.RTL : EDirection.LTR;
  }, [isTranslation, record]);

  const [editMode, setEditMode] = useState(recordTextStore.editedPhraseIndex === phraseIndex);

  useEffect(() => {
    return reaction(
      () => recordTextStore.editedPhraseIndex,
      () => {
        setEditMode(recordTextStore.editedPhraseIndex === phraseIndex);
      },
    );
  }, [phraseIndex, recordTextStore.editedPhraseIndex]);

  const modDivOutView = useMemo(
    () =>
      clsx(
        classes.phraseLine,
        phrase.channelNumber === 0 && (stereo || phraseDirection !== 'rtl')
          ? classes.phraseLine_left
          : classes.phraseLine_right,
        phrase.dictor && hideDictor ? classes.phrase_Hidden : '',
        'noSelectText',
      ),
    [classes, hideDictor, phrase.channelNumber, phrase.dictor, phraseDirection, stereo],
  );

  const phraseTime = useMemo(
    () => moment.duration(phrase.tokens.at(0)?.begin, 'seconds').format('hh:mm:ss', { trim: false }),
    [phrase.tokens],
  );

  useEffect(() => {
    function hiddenDictors() {
      if (phrase.dictor && hidePhrases.includes(phrase.dictor.id)) {
        setHideDictor(true);
      } else {
        setHideDictor(false);
      }
    }

    hiddenDictors();
  }, [hidePhrases, phrase.dictor]);

  const handleSelectDictor = useCallback(
    (dictor: IDictor) => {
      const range = selectionsStore.getFromToOrdered();
      selectionsStore.reset();

      if (range !== undefined) {
        onCutBubble(phraseIndex, range[0], range[1], dictor);
      }
    },
    [onCutBubble, phraseIndex, selectionsStore],
  );

  if (editMode && !(phrase.dictor && hideDictor)) {
    const dictorBubbleCount = phrases.filter(
      ph => phrase.dictor && ph.dictor && phrase.dictor.id === ph.dictor.id,
    ).length;
    const isSignleDictorPhrase = dictorBubbleCount === 1;

    return (
      <PhraseEdited
        phraseIndex={phraseIndex}
        phrase={phrase}
        stereo={stereo}
        isSignleDictorPhrase={isSignleDictorPhrase}
        phraseDirection={phraseDirection}
        handleEditableFocused={handleEditableFocused}
        handleEditComplete={handleEditComplete}
        handleEditKeyDown={handleEditKeyDown}
        canDictorChange={isSelectPossible()}
      />
    );
  }

  const getStringFromPhrase = (phrase: IPhrase): TPhraseNode[] => {
    const phraseString: TPhraseNode[] = [];

    phrase.tokens.forEach(({ text, stop_sign }) => {
      const end = stop_sign ? (stop_sign.includes('\n') ? stop_sign.replaceAll('\n', '') : `${stop_sign}  `) : '  ';

      phraseString.push(`${text}${end}`);

      if (stop_sign?.includes('\n')) {
        stop_sign.split('').forEach(breakLine => {
          phraseString.push(<br />);
        });
      }
    });

    return phraseString;
  };

  return (
    <div
      key={phraseIndex}
      className={modDivOutView}
      onDoubleClick={e => {
        clear();
        onClick(e, phraseIndex);
      }}
    >
      <ClickAwayListener onClickAway={() => clear()}>
        <div
          ref={thisRef}
          onMouseLeave={() => {
            onSelectionEnd(undefined);
          }}
          onMouseUp={e => {
            if (e.detail === 1) {
              e.stopPropagation();
              onSelectionEnd(undefined);

              return;
            } else {
              selectionsStore.reset();
            }
          }}
          className={clsx(
            classes.phrase,
            phrase.channelNumber === 0
              ? isTranslation
                ? classes.phrase_left_tr
                : classes.phrase_left
              : isTranslation
              ? classes.phrase_right_tr
              : classes.phrase_right,
            {
              [classes.phrase_rtl]: phraseDirection === 'rtl',
            },
          )}
          style={{
            backgroundColor: phrase.dictor?.__color ? alpha(phrase.dictor.__color, 0.2) : undefined,
            pointerEvents: 'auto',
          }}
        >
          {phrase.dictor && (
            <Box pb={1} display="flex">
              <DictorChip dictor={phrase.dictor} cache={avatarCache} />
              <Box flexGrow={1} p={1} />
              <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.8em' }}>
                {phraseTime}
              </Typography>
            </Box>
          )}

          {phrase.tokens.map((t: IWord, tokenIndex: number) => {
            if (t.stop_sign?.includes('\n')) {
              return t.stop_sign.split('').map(x => <br />);
            }

            return (
              <PhraseToken
                onMouseDown={e => {
                  e.stopPropagation();
                  onSelectionMouseDown(tokenIndex);
                }}
                onMouseMove={e => {
                  e.stopPropagation();
                  selectionOver(tokenIndex);
                }}
                onMouseUp={e => {
                  if (e.detail === 1) {
                    e.stopPropagation();
                    onSelectionEnd(tokenIndex);

                    return;
                  } else {
                    selectionsStore.reset();
                  }
                }}
                selectionStore={selectionsStore}
                id={getTokenId(phraseIndex, tokenIndex)}
                tokenIndex={tokenIndex}
                key={tokenIndex}
                text={t}
                dictor={phrase.dictor}
                hChangeDictor={handleSelectDictor}
                isTokenFiltered={t => Boolean(t.isFiltered)}
              />
            );
          })}
        </div>
      </ClickAwayListener>
    </div>
  );
});
