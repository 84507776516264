import { gAPP_STORE } from '@/app/app-store';
import { EditableTextArea } from '@/components/summary/EditableTextArea';
import { Timecodes } from '@/components/summary/Timecodes';
import { ITopic } from '@/components/summary/types';

interface ITopicPanel {
  topic: ITopic;
  isTranslate?: boolean;
  autotest: string;
}

export const TopicPanel = ({ topic, isTranslate, autotest }: ITopicPanel) => {
  const { updateTopic } = gAPP_STORE.summaryStore;
  const recordsStore = gAPP_STORE.getRecordsStore();

  const handleEdit = async (value: string, field: keyof ITopic) => {
    if (value !== topic[field]) {
      await updateTopic(topic, field, value);
      await recordsStore.mapAISummaryToRecords(recordsStore.selectedRecords);
      await recordsStore.loadSummaryTopicForRecord(recordsStore.selectedRecords[0].id);
    }
  };

  return (
    <div
      data-autotest={autotest}
      key={autotest}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '10px' }}>
        <div
          data-autotest={`${autotest}-title`}
          key={'topic-title'}
          style={{ fontSize: '16px', fontFamily: 'Plus Jakarta Sans, sans-serif', fontWeight: 700 }}
        >
          <EditableTextArea
            id={'topic-title'}
            text={topic.title}
            onEdit={value => handleEdit(value, 'title')}
            placeholder="summary.enterTitle"
            emptyValue="--"
            maxLength={200}
            readOnly={isTranslate}
          />
        </div>
        <div
          data-autotest={`${autotest}-description`}
          key={'topic-description'}
          style={{ fontSize: '14px', fontFamily: 'Inter, sans-serif', fontWeight: 400 }}
        >
          <EditableTextArea
            id={'topic-description'}
            text={topic.description}
            onEdit={value => handleEdit(value, 'description')}
            placeholder="summary.enterDescription"
            emptyValue="--"
            maxLength={1200}
            readOnly={isTranslate}
          />
        </div>
      </div>
      <Timecodes
        timecodesItem={topic.timecodesItem}
        onShowOnPlayer={() => console.log('click on counter')}
        onClickTimeCode={t => console.log('click on time code: ', t)}
        autotest={autotest}
      />
    </div>
  );
};
