import { IReactionDisposer, reaction, runInAction } from 'mobx';

import { DataGridStore } from '@/react-ui-kit/src';
import {
  EntitySubscriptionIdManager,
  TEntitySubscriptionEvent,
  TEntitySubscriptionEventNotFound,
} from '@/services/events/entity-subscription-id-manager';
import { IEntityChangeEvent } from '@/types/events';
import { IEntityChangeSubscription } from '@/services/events/entity-change-events-service';
import { IEntryWithID } from '@/types/EntryWithId';

export class EntityChangeGridStoreWrapper<Tid, T extends IEntryWithID<Tid>, TEvent extends IEntryWithID<Tid>> {
  entityChangeEventManager: EntitySubscriptionIdManager<Tid, T, TEvent>;
  gridStore: DataGridStore<T>;
  reactionClean: IReactionDisposer[] = [];

  constructor(
    params: IEntityChangeSubscription[],
    gridStore: DataGridStore<T>,
    onEvent?: TEntitySubscriptionEvent<T, TEvent>,
    onUnknownId?: TEntitySubscriptionEventNotFound<TEvent>,
  ) {
    const entityChangeEventManager = new EntitySubscriptionIdManager<Tid, T, TEvent>(
      async (orig, event) => (onEvent ? await onEvent(orig, event) : await this.onRecordChanged(orig, event)),
      params,
      onUnknownId,
    );

    this.entityChangeEventManager = entityChangeEventManager;
    this.gridStore = gridStore;
  }

  async subscribe(s: string) {
    if (this.reactionClean.length > 0) {
      this.reactionClean.forEach(x => x());
      this.reactionClean = [];
    }
    this.reactionClean.push(
      reaction(
        () => this.gridStore.data,
        () => {
          this.entityChangeEventManager.setData(this.gridStore.data);
        },
      ),
    );

    this.reactionClean.push(
      reaction(
        () => this.gridStore.total,
        () => {
          this.entityChangeEventManager.setData(this.gridStore.data);
        },
      ),
    );

    this.entityChangeEventManager.setData(this.gridStore.data);
    await this.entityChangeEventManager.subscribe(`EntityChangeGridStoreWrapper.subscribe() for ${s}`);
  }
  async unsubscribe() {
    await this.entityChangeEventManager.unsubscribe();
    if (this.reactionClean.length > 0) {
      this.reactionClean.forEach(x => x());
      this.reactionClean = [];
    }
  }

  //record already observed!
  onRecordChanged(record: T, event: IEntityChangeEvent<TEvent>) {
    runInAction(() => {
      Object.assign(record, event.value);
    });

    return Promise.resolve();
  }
}
