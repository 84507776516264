import { useCallback, useMemo, useState } from 'react';

import { Box } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditIcon from '@material-ui/icons/Edit';

import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

import { DataGrid, ECommonDialogCloseStatus, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';

import { IGroup } from './types';
import GroupDialog, { IDeleteDialogResult, IGroupDialogResult } from './group-dialog';
import DialogDeleteGroup from './group-dialog-delete';

import { gAPP_STORE } from '@/app/app-store';

interface ICreateEditDialog {
  isOpen: boolean;
  group?: IGroup;
}

const GroupsGrid = () => {
  const { t } = useTranslation();
  const groupStore = gAPP_STORE.getGroupsStore();
  const [openGroupDialog, setOpenGroupDialog] = useState<ICreateEditDialog>({ isOpen: false });
  const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState(false);

  const handleCreateGroup = useCallback(() => {
    setOpenGroupDialog({ isOpen: true });
  }, []);

  const handleEditGroup = useCallback(() => {
    if (groupStore.gridStore.selectedRow) {
      setOpenGroupDialog({ isOpen: true, group: groupStore.gridStore.selectedRow });
    }
  }, [groupStore.gridStore.selectedRow]);

  const handleDeleteGroup = useCallback(() => {
    if (groupStore.gridStore.selectedRow) {
      setOpenDeleteGroupDialog(true);
    }
  }, [groupStore.gridStore.selectedRow]);

  const handleCloseGroupDialog = (dialogResult: IGroupDialogResult) => {
    setOpenGroupDialog({ isOpen: false });

    if (dialogResult.closeStatus === ECommonDialogCloseStatus.CANCEL) return;

    if (dialogResult.group.id !== -1 && dialogResult.oldValue) {
      // Update group
      groupStore.update(dialogResult.group, dialogResult.oldValue);
    } else {
      // Create group
      groupStore.create(dialogResult.group);
    }
  };

  const handleCloseGroupDeleteDialog = async (dialogResult: IDeleteDialogResult) => {
    setOpenDeleteGroupDialog(false);
    if (dialogResult.closeStatus === ECommonDialogCloseStatus.CANCEL) return;

    const selectedGroup = groupStore.gridStore.selectedRow;
    if (selectedGroup) {
      groupStore.delete(selectedGroup);
      await gAPP_STORE.getUsersStore().getAll();
    }
  };

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
    () => [
      { type: 'text', text: `${t('groups.title')}`, pr: 1 },
      { type: 'divider', pl: 1 },
      {
        type: 'button',
        text: 'create',
        icon: <AddIcon />,
        onClick: handleCreateGroup,
        pl: 1,
      },
      {
        type: 'button',
        text: 'edit',
        icon: <EditIcon />,
        onClick: handleEditGroup,
        disabled: groupStore.gridStore.selectedRow ? false : true,
        pl: 1,
      },
      {
        type: 'button',
        text: 'delete',
        icon: <ClearRoundedIcon />,
        onClick: handleDeleteGroup,
        disabled: groupStore.gridStore.selectedRow ? false : true,
        color: 'red',
        pl: 1,
      },
    ],
    [groupStore.gridStore.selectedRow, handleCreateGroup, handleDeleteGroup, handleEditGroup, t],
  );

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid
          id="GroupsGrid"
          cols={groupStore.gridCols}
          gridStore={groupStore.gridStore}
          isNeedTooltipForContent={true}
          noPaging
        />
      </Box>

      {openGroupDialog.isOpen && <GroupDialog onClose={handleCloseGroupDialog} group={openGroupDialog.group} />}
      {openDeleteGroupDialog && (
        <DialogDeleteGroup onClose={handleCloseGroupDeleteDialog} group={groupStore.gridStore.selectedRow} />
      )}
    </Panel>
  );
};

export default observer(GroupsGrid);
