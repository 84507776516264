import { reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Box } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import CachedIcon from '@material-ui/icons/Cached';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LabelIcon from '@material-ui/icons/Label';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

import { DataGrid, ECommonDialogCloseStatus, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';

import { gAPP_STORE } from 'app/app-store';

import { useHistory } from 'react-router-dom';

import { DictorDiagramsForReport } from '../dictors/DictorDiagramsForReport';
import DictorStatisticsDialog from '../dictors/DictorStatisticsDialog';
import { NotifyErrorMsg } from '../notifications/NotifyErrorMsg';
import { NotificationCode } from '../notifications/types';
import PlayerWithDictors from '../player-with-dictors/PlayerWithDictors';
import { IconHideSummary, IconShowSummary } from '../summary/AIicons';
import { CommonSummaryPanel } from '../summary/CommonSummaryPanel';
import { contentBase64ToDoc } from '../templates/templates-utils';

import DialogChangeRecordGroups, { IChangeGroupsDialogResult } from './dialog-change-record-groups';
import DialogChangeLabels, { ILabelsDialogResult } from './dialogs/Labels/DialogChangeLabels';
import { DialogCreate } from './dialogs/UploadFiles/dialogUploadFiles';
import { recordsGridColumns } from './records-grid-columns';
import ToFileDialog from './to-file-dialog';
import { ERecordStatus, ILabel, IRecord, IToFileDialogResult, IUploadResult2 } from './types';

import { gridProxyService } from '@/services/grid-proxy/grid-proxy-service';
import { ILabelUpdate, labelService } from '@/services/labels/labels-service';
import { ExportFileExtension } from '@/types/common';
import { intersactionOfTwoSets } from '@/utils/setIntersaction';

const TIMER_DELAY = 1000;

let timerId: NodeJS.Timeout | undefined = undefined;
//TODO: убрать мутации объекта внутри компонента(всё должно быть через setState)
const RecordsGrid = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const recordsStore = gAPP_STORE.getRecordsStore();
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const notificationsStore = gAPP_STORE.getNotificationsStore();
  const summaryStore = gAPP_STORE.summaryStore;

  const recordsSettingsStore = recordsStore.gridStore?.gridSettingsStore;

  useEffect(() => {
    function firstRenderFn() {
      recordsSettingsStore?.colsLayout.forEach(cl => {
        if (cl.column.id === 'topics') {
          runInAction(() => {
            cl.visible = gAPP_STORE.avocado_topics === true;
          });
        }
        if (cl.column.id === 'groups') {
          if (!gAPP_STORE.groups) {
            runInAction(() => {
              cl.visible = false;
            });
          }
        }
      });
    }

    firstRenderFn();
    // дизейблим потомучто ф-ия вызывается только оди раз и нет смысла ещё раз вызывать useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void recordsStore.gridStoreEventWrapper.subscribe('RecordsGrid UE[recordsStore.gridStoreEventWrapper]');

    return () => void recordsStore.gridStoreEventWrapper.unsubscribe();
  }, [recordsStore.gridStoreEventWrapper]);

  useEffect(() => {
    const disposer = reaction(
      () => ({
        avocadoTopics: gAPP_STORE.avocado_topics,
        groups: gAPP_STORE.groups,
        colsLayout: recordsSettingsStore?.colsLayout,
      }),
      ({ avocadoTopics, groups, colsLayout }) => {
        if (colsLayout) {
          colsLayout.forEach(cl => {
            if (cl.column.id === 'topics') {
              if (!avocadoTopics) {
                cl.visible = true;
              }
            }
            if (cl.column.id === 'groups') {
              if (!groups) {
                cl.visible = false;
              }
            }
          });
        }
      },
    );

    return () => {
      disposer();
    };
  }, [recordsSettingsStore.colsLayout]);

  useEffect(() => {
    function disposer() {
      recordsSettingsStore?.colsLayout.forEach(cl => {
        if (cl.column.id === 'topics') {
          if (!gAPP_STORE.avocado_topics) {
            cl.isHidden = true;
          }
        }
        if (cl.column.id === 'groups') {
          if (!gAPP_STORE.groups) {
            cl.isHidden = true;
          }
        }
      });
    }

    disposer();
  }, [recordsSettingsStore.colsLayout]);

  const [openToFileDialog, setOpenToFileDialog] = useState(false);
  const [openUpDialog, setOpenUpDialog] = useState(false);
  const [initValue] = useState<IUploadResult2[]>([]);

  const [openChangeLabelsDialog, setOpenChangeLabelsDialog] = useState(false);
  const [resultLabelsDialog] = useState<ILabelsDialogResult>({
    recordIds: [],
    oldLabels: [],
    addLabels: [],
    updateLabels: [],
    delLabels: [],
    closeStatus: 0,
  });

  const [openDialogChangeRecordGroups, setOpenDialogChangeRecordGroups] = useState(false);
  const [recordDialogGroupsResult, setRecordDialogGroupResult] = useState<IChangeGroupsDialogResult>({
    records: [],
    oldRecordsGroups: [],
    newRecordsGroups: [],
    closeStatus: 0,
  });
  const personCardsStore = gAPP_STORE.getPersonCardsStore();

  useEffect(() => {
    const reloadRecords = async () => {
      const data = gAPP_STORE.dataGridAnswer;
      if (!data?.ExtraMetadata) {
        await recordsStore.gridStore.reload();
        await dictorsStore.reloadStore();
      } else {
        let isFlag = false;

        try {
          const x = await gAPP_STORE.loadState();
          isFlag = true;
          if (x) {
            initValue.push(x);
            setOpenUpDialog(true);
            gAPP_STORE.getDictorsStore().setStatus('success');

            return;
          }

          if (data.Cards.length) {
            const groups = data.Cards[0].AccessGroups;

            const dictor = personCardsStore.getStoredDictorAndUpdateFromCard(data.Cards[0]);

            if (dictor) {
              const dictorUpdate = {
                ...dictor,
                groupsId: groups,
              };

              await dictorsStore.updateDictorWithGroups(dictorUpdate);
              await dictorsStore.reloadStore();
            } else {
              await dictorsStore.reloadStore();
              throw new Error('Internal error. No dictor in personCardsStore. Cannot create   personal card.');
            }
          }
        } catch (e) {
          if (!isFlag) {
            await dictorsStore.reloadStore();
          }
        }
        if (!gAPP_STORE.loginStore.isSignedIn()) {
          history.push('/login');
        }
      }
    };

    reloadRecords();
  }, [recordsStore.gridStore, dictorsStore, initValue, personCardsStore, history]);

  useEffect(() => {
    return reaction(
      () => recordsStore.data.records.filter(c => c.status === ERecordStatus.PROCESSED).map(c => c.id),
      (oldv, newv) => {
        const added = newv.filter(id => !oldv.includes(id));
        const deled = oldv.filter(id => !newv.includes(id));
        if (added.length > 0 || deled.length > 0) {
          dictorsStore.reloadDictors();
        }
      },
    );
  }, [recordsStore.data.records, dictorsStore]);

  useEffect(() => {
    if (!timerId) {
      timerId = setTimeout(async function tick() {
        if (timerId) {
          if (gAPP_STORE.loginStore.isSignedIn()) {
            //await recordsStore.getStatsData();
            await notificationsStore.loadActiveAlarms();
            const alarms = notificationsStore.activeAlarms.filter(a => !a.read);
            alarms.forEach(a => {
              const hide = a.code === NotificationCode.CODE_CLEANER_WARN && !gAPP_STORE.loginStore.user?.isAdmin;
              if (!hide) {
                toast.error(<NotifyErrorMsg notification={a} />, {
                  toastId: a.id,
                  autoClose: false,
                  onClick: () => {
                    notificationsStore.markNotificationRead(a);
                  },
                });
              }
            });
            timerId = setTimeout(tick, TIMER_DELAY);
            const record = recordsStore.gridStore.selectedRow;
            const allRecords = recordsStore.gridStore.data;
            const filteredRecords = allRecords.filter(
              record =>
                record.summaryStatus && !record.topicCount && record.summaryStatus > 2 && record.summaryStatus !== 4,
            );
            await recordsStore.mapAISummaryToRecords(filteredRecords);
          } else {
            timerId = undefined;
          }
        }
      }, TIMER_DELAY);
    }

    const disp2 = reaction(
      () => recordsStore.gridStore.selectedRow,
      async () => {
        gAPP_STORE.soundStore.resetRecord();
        gAPP_STORE.getRecordTextStore().clear();
        gAPP_STORE.videoStore.reset();
        gAPP_STORE.getDictorsStore().resetHide();

        const record = recordsStore.gridStore.selectedRow;
        if (record) {
          gAPP_STORE.soundStore.setRecord(record);
          gAPP_STORE.videoStore.load();
          await gAPP_STORE.getRecordTextStore().setRecord(record);
        } else {
          gAPP_STORE.summaryStore.setSummaryPanel(false);
        }
      },
    );

    const disp3 = reaction(
      () => recordsStore.gridStore.isPending,
      (isPending: boolean) => {
        if (isPending) {
          gAPP_STORE.getRecordTextStore().clear();
          gAPP_STORE.videoStore.reset();
          gAPP_STORE.soundStore.resetRecord();
          gAPP_STORE.getDictorsStore().resetHide();
        }
      },
    );

    return () => {
      if (timerId) {
        timerId = undefined; //stop repeating
      }
      disp2();
      disp3();
    };
  }, [notificationsStore, recordsStore]);

  useEffect(() => {
    if (summaryStore.openSummaryPanel && recordsStore.gridStore.selectedRow === undefined) {
      summaryStore.setSummaryPanel(false);
    }
  }, [recordsStore.gridStore.selectedRow, summaryStore, summaryStore.openSummaryPanel]);

  const selectedRecord = gAPP_STORE.getRecordsStore().gridStore.selectedRow;

  const diagramsForReport = useMemo(
    () =>
      selectedRecord && dictorsStore.isSegmentationLoaded ? (
        <DictorDiagramsForReport selectedRecordCorrelationId={selectedRecord.correlationId} />
      ) : null,
    [dictorsStore.isSegmentationLoaded, selectedRecord],
  );

  const handleRefresh = useCallback(async () => {
    await recordsStore.reload();
    await dictorsStore.reloadStore();
  }, [recordsStore, dictorsStore]);

  const refreshAll = useCallback(() => {
    recordsStore.reload();
    dictorsStore.reloadStore();
  }, [dictorsStore, recordsStore]);

  const handleDeleteRecord = useCallback(async () => {
    const records: IRecord[] = recordsStore.selectedRecords;

    if (!records.length) return;

    if (window.confirm(t('records.deleteConfirm'))) {
      const promises = records.map(record => recordsStore.delete(record));
      await Promise.allSettled(promises);
      refreshAll();
    }
  }, [recordsStore, t, refreshAll]);

  // Labels handlers
  // --------------------------------------------------------------------------------
  const handleChangeRecordLabel = useCallback(() => {
    resultLabelsDialog.recordIds = [];
    resultLabelsDialog.oldLabels = [];

    const checkRecords = recordsStore.gridStore.getCheckedRows();
    //----отмечена 1 запись
    if (checkRecords.length === 1) {
      const record = checkRecords[0];
      resultLabelsDialog.recordIds = [record.id];
      resultLabelsDialog.oldLabels = record.labelLists || [];
    }
    //----отмечено много записей
    if (checkRecords.length > 1) {
      let indMinLabels = 0;
      let minLabels = checkRecords[0].labelLists ? checkRecords[0].labelLists.length : 0;
      resultLabelsDialog.recordIds = checkRecords.map((record, index) => {
        if (!record.labelLists) {
          indMinLabels = index;
          minLabels = 0;
        } else {
          if (record.labelLists.length < minLabels) {
            minLabels = record.labelLists.length;
            indMinLabels = index;
          }
        }

        return record.id;
      });

      if (minLabels > 0) {
        let minLabelSet = new Set(checkRecords[indMinLabels].labelLists?.map(label => label.typeId));
        checkRecords.forEach((record, index) => {
          if (index !== indMinLabels) {
            minLabelSet = intersactionOfTwoSets(minLabelSet, new Set(record.labelLists?.map(label => label.typeId)));
          }
        });
        if (minLabelSet.size > 0) {
          const mapRecordLabels = new Map<string, ILabel[]>();
          minLabelSet.forEach(labelType => mapRecordLabels.set(labelType, []));
          checkRecords.forEach(record => {
            record.labelLists?.forEach(label => {
              if (minLabelSet.has(label.typeId)) {
                mapRecordLabels.get(label.typeId)?.push(label);
              }
            });
          });
          mapRecordLabels.forEach(item => {
            resultLabelsDialog.oldLabels.push({ ...item[0], comment: '' });
          });
          resultLabelsDialog.mapRecordLabels = mapRecordLabels;
        }
      }
    }
    //----выбран 1 запись
    if (resultLabelsDialog.recordIds.length === 0) {
      const record = recordsStore.gridStore.selectedRow;
      if (record) {
        resultLabelsDialog.recordIds = [record.id];
        resultLabelsDialog.oldLabels = record.labelLists || [];
      }
    }

    if (resultLabelsDialog.recordIds.length > 0) {
      resultLabelsDialog.addLabels = [];
      resultLabelsDialog.updateLabels = [];
      resultLabelsDialog.delLabels = [];
      resultLabelsDialog.closeStatus = 0;
      setOpenChangeLabelsDialog(true);
    }
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsStore.gridStore.checkedRows, recordsStore.gridStore.selectedRow, resultLabelsDialog]); //нужна реакция на поля recordsStore.gridStore

  const handleLabelsDialogClose = useCallback((dialogResult: ILabelsDialogResult) => {
    if (dialogResult.closeStatus === ECommonDialogCloseStatus.OK) {
      const adds: ILabelUpdate[] = [];
      const dels: ILabelUpdate[] = [];
      const changes: ILabelUpdate[] = [];

      dialogResult.addLabels.forEach(label => {
        dialogResult.recordIds.forEach(id => {
          adds.push({
            id: 0,
            recordId: id,
            typeId: label.typeId,
            comment: label.comment ?? '',
          });
        });
      });
      if (dialogResult.mapRecordLabels) {
        //multicheck
        const mm = dialogResult.mapRecordLabels;
        dialogResult.delLabels.forEach(lbl => {
          mm.get(lbl.typeId)?.forEach(label => {
            dels.push({
              id: label.id ?? 0,
              recordId: label.recordId ?? 0,
              typeId: label.typeId,
              comment: label.comment ?? '',
            });
          });
        });
        dialogResult.updateLabels.forEach(lbl => {
          mm.get(lbl.typeId)?.forEach(label => {
            changes.push({
              id: label.id ?? 0,
              recordId: label.recordId ?? 0,
              typeId: label.typeId,
              comment: label.comment ?? '',
            });
          });
        });
      } else {
        //select 1 record
        dialogResult.delLabels.forEach(lbl => {
          dels.push({
            id: lbl.id ?? 0,
            recordId: dialogResult.recordIds[0],
            typeId: lbl.typeId,
            comment: lbl.comment ?? '',
          });
        });
        dialogResult.updateLabels.forEach(lbl => {
          changes.push({
            id: lbl.id ?? 0,
            recordId: dialogResult.recordIds[0],
            typeId: lbl.typeId,
            comment: lbl.comment ?? '',
          });
        });
      }

      labelService.updateRecordsBylabels(adds, dels, changes);
    }
    dialogResult.mapRecordLabels = undefined;

    setOpenChangeLabelsDialog(false);
  }, []);

  // Groups handlers
  // --------------------------------------------------------------------------------

  const handleChangeRecordGroups = useCallback(() => {
    const record = recordsStore.gridStore.selectedRow;
    if (record) {
      const recordGroups = record.groups || [];
      setRecordDialogGroupResult({
        records: [record],
        oldRecordsGroups: [...recordGroups],
        newRecordsGroups: [...recordGroups],
        closeStatus: ECommonDialogCloseStatus.CANCEL,
      });
      setOpenDialogChangeRecordGroups(true);
    }
  }, [recordsStore.gridStore.selectedRow]);

  const handleCloseRecordDialog = useCallback(
    (dialogResult: IChangeGroupsDialogResult) => {
      setOpenDialogChangeRecordGroups(false);

      if (dialogResult.closeStatus === ECommonDialogCloseStatus.OK) {
        const records = dialogResult.records || [];
        if (records.length < 1) return;
        const record = records[0];
        const oldGroupIds = (dialogResult.oldRecordsGroups || []).map(g => g.id);
        const newGroupIds = (dialogResult.newRecordsGroups || [])
          .map(g => g.id)
          .filter((v, i, a) => a.indexOf(v) === i); // unique values

        const addedGroupsIds = newGroupIds.filter(newId => oldGroupIds.findIndex(oldId => oldId === newId) < 0);
        const removedGroupsIds = oldGroupIds.filter(oldId => newGroupIds.findIndex(newId => oldId === newId) < 0);

        const changeGroupsRequests: Promise<void>[] = [];
        const groupStore = gAPP_STORE.getGroupsStore();
        if (removedGroupsIds.length > 0) {
          changeGroupsRequests.push(groupStore.removeGroupsFromRecord(record.id, removedGroupsIds));
        }

        if (addedGroupsIds.length > 0) {
          changeGroupsRequests.push(groupStore.addGroupsToRecord(record.id, addedGroupsIds));
        }

        if (changeGroupsRequests.length > 0) Promise.allSettled(changeGroupsRequests).then(() => recordsStore.reload());
      }
    },
    [recordsStore],
  );

  //#region toFile
  const handleToFile = useCallback(() => {
    const record = recordsStore.gridStore.selectedRow;
    if (record && record.status === ERecordStatus.PROCESSED) {
      setOpenToFileDialog(true);
    } else {
      toast.info(t('RecordNotReadyToReport'));
    }
  }, [recordsStore.gridStore.selectedRow, t]);

  const handleToArchive = () => {
    gridProxyService.searchInArchive();
  };

  const handleCloseToFileDialog = useCallback(
    (dialogResult: IToFileDialogResult, fileExtension: ExportFileExtension) => {
      setOpenToFileDialog(false);

      if (dialogResult.closeStatus === ECommonDialogCloseStatus.OK) {
        if (dialogResult.template) {
          try {
            const templateBase64 = atob(dialogResult.template.template);
            const doc = contentBase64ToDoc(templateBase64, true);
            const selectedRecord = recordsStore.gridStore.selectedRow;

            if (doc && selectedRecord) {
              const textStore = gAPP_STORE.getRecordTextStore();
              textStore.createExportFile(doc, selectedRecord, dialogResult, fileExtension);

              //to log about succes
              const templateName = dialogResult.template?.templateName || '';
              textStore.getDownLoadInform(fileExtension, selectedRecord.caseid, templateName, selectedRecord.fileName);
            }
          } catch (e) {
            toast.error(t('reportShablonBad'));
          }
        }
      }
    },
    [t, recordsStore.gridStore],
  );
  //#endregion

  const handleSummary = useCallback(() => {
    summaryStore.setSummaryPanel(!summaryStore.openSummaryPanel);
  }, [summaryStore]);

  const handleUpOpen = useCallback(async () => {
    await gAPP_STORE.loginStore.updateGroups();
    setOpenUpDialog(true);
  }, []);

  const handleUpClose = useCallback(
    (status: ECommonDialogCloseStatus) => {
      initValue.length = 0;
      setOpenUpDialog(false);
    },
    [initValue],
  );

  const noRecordsSelected = useMemo(
    () => recordsStore.selectedRecords.length === 0,
    [recordsStore.selectedRecords.length],
  );

  const toolbarDescriptor = useMemo(() => {
    const noSelectedRow = recordsStore.gridStore.selectedRow === undefined;

    const res: IToolbarExDescriptorItem[] = [
      { type: 'button', text: 'refresh', icon: <CachedIcon />, onClick: handleRefresh, color: 'green', pl: 1 },
      { type: 'divider', pl: 1 },
      {
        type: 'button',
        text: 'records.uploadRecords',
        icon: <CloudUploadIcon />,
        onClick: handleUpOpen,
      },
      { type: 'divider', pl: 1 },
      { type: 'filler' },
    ];
    if (gAPP_STORE.showChangeLabelsButton) {
      res.push({
        type: 'button',
        text: 'records.changeLabels',
        icon: <LabelIcon />,
        disabled: recordsStore.gridStore.selectedRow === undefined && recordsStore.gridStore.checkedRows.length === 0,
        onClick: handleChangeRecordLabel,
        pl: 1,
      });
    }

    if (gAPP_STORE.showSummaryAI) {
      const openSummaryPanel = summaryStore.openSummaryPanel;
      res.push({
        type: 'button',
        text: openSummaryPanel ? 'summary.buttonHide' : 'summary.buttonShow',
        icon: openSummaryPanel ? (
          <IconHideSummary color="#0092DD" disabled={noSelectedRow} />
        ) : (
          <IconShowSummary color="#0092DD" disabled={noSelectedRow} />
        ),
        disabled: noSelectedRow,
        onClick: handleSummary,
        color: '#0092DD',
        pl: 1,
      });
    }

    if (gAPP_STORE.gridProxyUrl && gAPP_STORE.gridProxyUrl.length > 0) {
      res.push(
        { type: 'divider', pl: 1 },
        {
          type: 'button',
          text: 'searchInArchive',
          icon: <ArchiveOutlinedIcon />,
          onClick: handleToArchive,
          pl: 1,
        },
      );
    }

    if (gAPP_STORE.groups && gAPP_STORE.loginStore.user?.isAdmin) {
      res.push({
        type: 'button',
        text: 'records.changeGroup',
        icon: <Group />,
        disabled: noSelectedRow,
        onClick: handleChangeRecordGroups,
        pl: 1,
      });
    }

    res.push(
      { type: 'divider', pl: 1 },
      {
        type: 'button',
        text: 'delete',
        icon: <ClearRoundedIcon />,
        onClick: handleDeleteRecord,
        disabled: noRecordsSelected,
        color: 'red',
        pl: 1,
      },
    );

    res.push(
      { type: 'divider', pl: 1 },
      {
        type: 'button',
        text: 'saveRecordShort',
        icon: <SaveRoundedIcon />,
        onClick: handleToFile,
        disabled: !recordsStore.gridStore.selectedRow,
        pl: 1,
      },
    );

    return res;
  }, [
    handleRefresh,
    handleUpOpen,
    recordsStore.gridStore.checkedRows.length,
    recordsStore.gridStore.selectedRow,
    handleChangeRecordLabel,
    handleToFile,
    summaryStore.openSummaryPanel,
    handleSummary,
    handleChangeRecordGroups,
    handleDeleteRecord,
    noRecordsSelected,
  ]);

  const dlgUpRecords = useMemo(
    () =>
      openUpDialog ? (
        <DialogCreate isOpen={openUpDialog} closeDialog={handleUpClose} initValues={initValue[0]} />
      ) : (
        <></>
      ),
    [handleUpClose, initValue, openUpDialog],
  );

  const dlgChangeLabels = useMemo(
    () =>
      openChangeLabelsDialog ? (
        <DialogChangeLabels
          isOpen={openChangeLabelsDialog}
          closeDialog={handleLabelsDialogClose}
          dialogResult={resultLabelsDialog}
        />
      ) : (
        <></>
      ),
    [openChangeLabelsDialog, handleLabelsDialogClose, resultLabelsDialog],
  );

  const pnlSummary = useMemo(
    () => (
      <Panel height={'100%'} width={'100%'}>
        <CommonSummaryPanel record={recordsStore.gridStore.selectedRow} />
      </Panel>
    ),
    [recordsStore.gridStore.selectedRow],
  );

  const pnlMainTable = useMemo(
    () => (
      <Panel style={{ marginLeft: '0.15em', marginRight: '0.15em', overflowX: 'hidden' }}>
        <ToolbarEx descriptor={toolbarDescriptor} style={{ overflowX: 'auto' }} />
        <Box display="flex" flexGrow={1}>
          <DataGrid id={'CommonGrid'} cols={recordsGridColumns()} gridStore={recordsStore.gridStore} noPageNumbers />
        </Box>
      </Panel>
    ),
    [recordsStore.gridStore, toolbarDescriptor],
  );

  const pnlMainTableAndSummary = useMemo(
    () => (
      <div
        style={{
          height: '100%',
          display: 'flex',
        }}
      >
        {pnlMainTable}
        <div
          style={{
            padding: 0,
            margin: 0,
            flex: '0 0 560px',
            borderLeft: '1px solid rgba(221, 221, 221, 1)',
          }}
        >
          {pnlSummary}
        </div>
      </div>
    ),

    [pnlSummary, pnlMainTable],
  );

  const pnlMain = useMemo(
    () => <>{summaryStore.openSummaryPanel ? pnlMainTableAndSummary : pnlMainTable}</>,
    [summaryStore.openSummaryPanel, pnlMainTable, pnlMainTableAndSummary],
  );

  return (
    <>
      <Panel style={{ marginLeft: '0.15em', marginRight: '0.15em' }}>{pnlMain}</Panel>

      {recordsStore.gridStore.selectedRow && (
        <Panel
          flexGrow={0}
          style={{ marginLeft: '0.15em', marginRight: '0.15em', marginTop: 0 }}
          borderTop="1px solid #ddd"
        >
          <PlayerWithDictors id="filesGridPlayer" />
          {/* after dictor refactoring (10805) second fix bug 10533  */}
          {gAPP_STORE.soundStore.isPlaying && (
            <DictorStatisticsDialog
              open={gAPP_STORE.getDictorsStore().openDictorsPanelVisible}
              onClose={() => gAPP_STORE.getDictorsStore().setOpenDictorsStatisticsDialog(false)}
            />
          )}
        </Panel>
      )}

      <DialogChangeRecordGroups
        open={openDialogChangeRecordGroups}
        onClose={handleCloseRecordDialog}
        dialogResult={recordDialogGroupsResult}
      />
      {dlgChangeLabels}
      {dlgUpRecords}
      <ToFileDialog open={openToFileDialog} onClose={handleCloseToFileDialog} />
      {recordsStore.gridStore.selectedRow && diagramsForReport}
    </>
  );
};

export default observer(RecordsGrid);
