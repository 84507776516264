import { makeAutoObservable, runInAction } from 'mobx';

import { INotification } from './types';

import { BackendService } from '@/services';

export class NotificationsStore {
  activeAlarms: INotification[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async loadActiveAlarms() {
    try {
      const filterData = {
        fields: [{ fieldName: 'mode', predicate: 'Eq', targetValues: [0] }],
        limit: 30,
        offset: 0,
        sortByField: { name: 'id', order: 'Dsc' },
      };

      const data = await BackendService.post(
        'notification/filter',
        JSON.stringify(filterData),
        false,
        false,
        false,
        undefined,
        undefined,
        true,
      );
      runInAction(() => {
        this.activeAlarms = data && Array.isArray(data) ? data : [];
      });
    } catch (error) {
      this.activeAlarms = [];
      console.error('Failed to load notifications:', error);
    }
  }

  async markNotificationRead(notification: INotification) {
    try {
      notification.read = true;
      await BackendService.put('notification', JSON.stringify(notification));
    } catch (error) {
      console.error('Failed to mark notifications READ');
    }
  }
}
