import { IDataSourceEntity } from '@/components/dataSources/types';
import { BackendService } from '@/services/backend-service';

class SourcesService {
  getAllSources = async () => {
    try {
      const result: IDataSourceEntity[] = await BackendService.get('channels/cisco/all');

      return result.sort((a: IDataSourceEntity, b: IDataSourceEntity) =>
        a.name === b.name ? 0 : a.name.localeCompare(b.name),
      );
    } catch (error) {
      console.log('GroupsService::getAllSources:', { error });
      throw error;
    }
  };

  //===============================================================================
  // administration of Sources
  //===============================================================================
  createSource = async (source: IDataSourceEntity) => {
    try {
      await BackendService.post(
        'channels',
        JSON.stringify({
          name: source.name,
          path: source.settings.spaceObjectId,
          settings: source.settings,
        }),
      );
    } catch (error) {
      console.log('GroupsService::updateSource:', { source, error });
      throw error;
    }
  };

  deleteSourceById = async (idSource: number) => {
    try {
      await BackendService.delete(`channels/${idSource}`);
    } catch (error) {
      console.log('GroupsService::deleteGroupById:', { idSource, error });
      throw error;
    }
  };

  updateSource = async (source: IDataSourceEntity) => {
    try {
      await BackendService.put('channels', JSON.stringify(source));
    } catch (error) {
      console.log('GroupsService::updateSource:', { source, error });
      throw error;
    }
  };
}

export const sourcesService = new SourcesService();
