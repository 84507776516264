import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useMemo, useRef, useState } from 'react';

import { Box, FormControl, FormGroup, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { ITemplate } from 'components/templates/i-template';

import { CommonDialog, CommonDialogUiStore } from '@uk';

import { gAPP_STORE } from 'app/app-store';

import { IToFileDialogResult } from './types';

import { exportFileExtensions, MAX_INPUT_EXPORT_FILENAME_LENGTH, Z_INDEX_COMMON_DIALOG } from '@/common/constants';
import { ExportFileExtension } from '@/types/common';

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

interface IToFileDialogProps {
  open: boolean;
  onClose: (dialogResult: IToFileDialogResult, fileExtension: ExportFileExtension) => void;
}
//TODO: переписать, убрать мутации объекта внутри компонента(всё должно быть через state и setState)
const ToFileDialog: React.FC<IToFileDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());
  const isEditor = gAPP_STORE.loginStore.user?.isEditor;

  const [isValid, setIsValid] = useState(true);

  const allowedExportFileExtensions = useMemo(() => {
    return exportFileExtensions.filter(ext => (ext === 'pdf' ? true : isEditor));
  }, [isEditor]);

  const [template, setTemplate] = useState<ITemplate>();
  const [fileFormat, setFileFormat] = useState<ExportFileExtension>(allowedExportFileExtensions[0]);

  const dialogResult: IToFileDialogResult = useMemo(
    () => ({
      closeStatus: 0,
      filename: t('defaultFilename'),
      showTime: true,
    }),
    [t],
  );

  userDlgUiStoreRef.current.setOkEnabled(isValid);

  const templateNames = useMemo(
    () => gAPP_STORE.getTemplateStore().data.templates,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gAPP_STORE.getTemplateStore().data.templates],
  );

  useMemo(() => {
    userDlgUiStoreRef.current.setOkEnabled(templateNames.length > 0);
    if (templateNames.length > 0) {
      setTemplate(templateNames[0]);
    }
  }, [templateNames]);

  const handleClose = (status: number) => {
    dialogResult.closeStatus = status;
    dialogResult.template = template;

    if (onClose) {
      onClose(dialogResult, fileFormat);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTemplateChange = (event: any) => {
    const id = event.target.value;
    const t = gAPP_STORE.getTemplateStore().data.templates.find(t => t.id === id);

    if (t) {
      setTemplate(t);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilenameChange = (event: any) => {
    if (event.target.value.length > 100) {
      event.target.value = dialogResult.filename;

      return;
    }
    if (!event.target.value.trim()) {
      setIsValid(false);

      return;
    }
    dialogResult.filename = event.target.value;
    setIsValid(true);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleShowTimeChange = (event: any) => {
    dialogResult.showTime = event.target.checked;
  };

  const getRecordName = (): string => {
    return gAPP_STORE.getRecordsStore().gridStore.selectedRow?.fileName ?? '';
  };

  const DialogBody = () => {
    return (
      <Box width={400}>
        <FormGroup>
          <Box
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '400px',
              textOverflow: 'ellipsis',
            }}
          >
            {t('exportRecords')}: {getRecordName()}
          </Box>
          <Box p={1} />
          <Box p={1} />
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id="select-template-label">{t('records.useTemplate')}</InputLabel>
            <Select
              labelId="select-template-label"
              id="select-template"
              value={template?.id || ''}
              onChange={handleTemplateChange}
              label={t('records.useTemplate')}
            >
              {templateNames.map((d, index) => (
                <MenuItem key={index} className={classes.option} value={d.id}>
                  {d.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box p={1} />

          <FormControl variant="outlined" fullWidth>
            <TextField
              required
              autoFocus
              label={t('filename')}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: MAX_INPUT_EXPORT_FILENAME_LENGTH }}
              defaultValue={dialogResult.filename}
              onChange={handleFilenameChange}
            />
          </FormControl>
          <Box p={1} />

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="select-file-format-label">{t('fileFormat')}</InputLabel>
            <Select
              labelId="select-file-format-label"
              value={fileFormat}
              onChange={e => setFileFormat(e.target.value as ExportFileExtension)}
              label={t('fileFormat')}
            >
              {allowedExportFileExtensions.map(ext => (
                <MenuItem key={ext} className={classes.option} value={ext}>
                  {ext}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box p={1} />
        </FormGroup>
      </Box>
    );
  };

  return (
    <CommonDialog
      style={{ zIndex: Z_INDEX_COMMON_DIALOG, position: 'absolute' }}
      title={'toFile'}
      open={open}
      onClose={handleClose}
      contentComponent={DialogBody}
      autotestId={'toFile'}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default observer(ToFileDialog);
