import { TRecordFields } from 'components/records/types';

import { IFilterField, TFilterSort } from './i-filter-field';

export interface IFilterData {
  userAccount?: IFilterField;
  userId?: IFilterField;
  actionType?: IFilterField;
  eventType?: IFilterField;
  message?: IFilterField;
  caseid?: IFilterField;
  createFrom?: IFilterField;
  createTo?: IFilterField;
  fileName?: IFilterField;
  languageResolved?: IFilterField;
  loadFrom?: IFilterField;
  loadTo?: IFilterField;
  meetingParticipants?: IFilterField;
  owner?: IFilterField;
  dictors?: IFilterField;
  speechDuration?: IFilterField;
  speechRecognition?: IFilterField;
  status?: IFilterField;
  textIdx?: IFilterField;
  topic?: IFilterField;
  topicThreshold?: IFilterField;
  translateIdx?: IFilterField;
  translationStatus?: IFilterField;
  wordsCount?: IFilterField;
  login?: IFilterField;
  privilege?: IFilterField;
  group_name?: IFilterField;
  groups_names?: IFilterField;
  topic_name?: IFilterField;
  topic_type?: IFilterField;
  template_name?: IFilterField;
  format?: IFilterField;
  channel_name?: IFilterField;
  filename?: IFilterField;
  dictor_name?: IFilterField;
  dictor_surname?: IFilterField;
  record_name?: IFilterField;
  processing_method?: IFilterField;
  loggedDatetime?: IFilterField;
  is_Kws?: IFilterField;
  is_auto_asr?: IFilterField;
  summaryText?: IFilterField;
  summaryInstructions?: IFilterField;
  summaryTopics?: IFilterField;
}

export type TFilterDataFields = keyof IFilterData;
export type TFilterDataFieldsWithCallFields = keyof IFilterData | keyof TRecordFields;

export interface IFilter {
  id: string;
  title: string;
  fixed: boolean;
  data: IFilterData;
}

export enum ERequestFilterPredicate {
  EQ = 'Eq',
  NOT_EQ = 'NotEq',
  GR = 'Gr',
  LESS = 'Less',
  EQ_GR = 'EqGr',
  EQ_LESS = 'EqLess',
  LIKE = 'Like',
  CONTAINS = 'Contains',
  IN = 'In',
  NOT_IN = 'NotIn',
}

export interface IRequestFilterField<T> {
  fieldName: T;
  predicate: ERequestFilterPredicate;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetValues: any[];
}

type TJsonFieldFieldName = 'actionParameters';

export interface IFilterJsonField<T> {
  fieldName: TJsonFieldFieldName;
  key: T;
  predicate: ERequestFilterPredicate;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetValues: any[];
}

export interface IRequestFilterData<T> {
  fields: IRequestFilterField<T>[];
  jsonFields?: IFilterJsonField<T>[];
  limit: number;
  offset: number;
  sortByField: {
    name: string;
    order: TFilterSort;
  };
  themes?: {
    ids: number[];
    threshold: number;
  };
  dictors?: IRequestFilterDataDictors;
  summaryFilter?: IRequestFilterSummary;
}

export interface IRequestFilterDataDictors {
  ids?: number[];
  meetingParticipants?: IValueAndPredicate;
  speechDuration?: IValueAndPredicate;
  wordsCount?: IValueAndPredicate;
}

export interface IRequestSummaryFilterField {
  value: string[];
  predicate: ERequestFilterPredicate;
}

export interface IRequestFilterSummary {
  text?: IRequestSummaryFilterField;
  topics?: IRequestSummaryFilterField;
  hasActions?: boolean;
}

export interface IValueAndPredicate {
  predicate: ERequestFilterPredicate;
  value: number;
}
