import moment from 'moment';

import { IParticipantDto, IMentionsDto, IOwnerDto, IParticipant, IDictor, IOwner } from '@/common/report-index';
import { date2str } from '@/common/utils';

export const strToDate = (d: string): Date | undefined => (d ? new Date(Date.parse(d)) : undefined);
export const strToDateStr = (d: string) => (d ? moment(strToDate(d)).format('YYYY-MM-DD') : '');
export const strToOnlyDateStr = (d: string) => strToDateStr(d).substring(0, 10);
export const dateToShortTimeStr = (d: Date) => (d ? (date2str(d) ?? '').substring(0, 16) : '');
export const dateToOnlyDateStr = (d: Date) => (d ? moment(d).format('YYYY-MM-DD').substring(0, 10) : '');

export interface IDictorName {
  id: number;
  name: string;
}

export const participantToExportString = (participant: IParticipant, names: IDictorName[]) => {
  const role = !!participant.role ? `(${participant.role})` : '';
  let title = participant.dictor ? '' : participant.summaryName ?? '';
  const ind = names.findIndex(v => v.id === participant.dictor?.id);
  if (ind >= 0) {
    title = names[ind].name;
  }

  return `${title} ${role}`;
};

export const getDictorsFromDto = (dictorsDto: IParticipantDto[], recordDictors: IDictor[]) => {
  const participants: IParticipant[] = [];

  dictorsDto.forEach(item => {
    const dictor = recordDictors.find(d => d.id === item.dictorId);
    if (dictor === undefined) {
      console.log('Not found dictor from summary:', { participant: item });
    } else {
      participants.push({
        idParticipant: item.id,
        dictor,
        summaryName: item.name,
        role: item.role ?? undefined,
      });
    }
  });

  return participants;
};

export const getOwnerFromDto = (
  participants: IParticipant[],
  owners: IOwnerDto[] | undefined,
  getTitle: (d: IDictor) => string,
  names?: IDictorName[],
): IOwner => {
  const result: IOwner = {
    idOwner: 0,
    name: '',
    editable: true,
  };

  const owner = owners && owners.length > 0 ? owners[0] : undefined;
  if (owner) {
    result.idOwner = owner.id;
    result.name = owner.name;
    if (owner.speakerId) {
      const participant = participants.find(p => p.idParticipant === owner.speakerId);
      if (participant && participant.dictor) {
        const id = participant.dictor.id;
        result.editable = false;
        result.name = getTitle(participant.dictor);
        if (names) {
          const ind = names.findIndex(v => v.id === id);
          if (ind >= 0) {
            result.name = names[ind].name;
          }
        }
      }
    }
  }

  return result;
};

export interface IFullNameWithRole {
  name: string;
  role: string;
}

export const sheldFullNameWithRole = (items: IFullNameWithRole[], isRTL: boolean): string => {
  if (items.length === 0) return '';
  let result = '';

  if (isRTL) {
    result = items.map(item => (item.role === '' ? item.name : `${item.name} (${item.role})`)).join('، '); // Используем арабскую запятую для разделения в RTL
  } else {
    // LTR кейс
    result = items.map(item => (item.role === '' ? item.name : `${item.name} (${item.role})`)).join(', ');
  }

  return result;
};

export const participantsToFullNameWithRole = (items: IParticipant[], names: IDictorName[]): IFullNameWithRole[] => {
  const result: IFullNameWithRole[] = [];
  items.forEach(item => {
    let title = item.dictor ? '' : item.summaryName ?? '';
    const ind = names.findIndex(v => v.id === item.dictor?.id);
    if (ind >= 0) {
      title = names[ind].name;
    }

    result.push({ name: title.trim(), role: item.role ? item.role.trim() : '' });
  });

  return result;
};

export const getRoleText = (item: IMentionsDto) => {
  const role = item.role ? item.role.trim() : '';

  return role === '' ? item.name : `${item.name} (${role})`;
};

export const getAllMentions = (mentionsDto: IMentionsDto[][]) => {
  const mentions = new Set<string>();
  mentionsDto.forEach(lst =>
    lst.forEach(item => {
      mentions.add(getRoleText(item));
    }),
  );

  return Array.from(mentions.values());
};

export const mentionsToFullNameWithRole = (
  topicIndex: number,
  mentionsDto: IMentionsDto[][],
  names: IDictorName[],
): IFullNameWithRole[] => {
  const result: IFullNameWithRole[] =
    topicIndex < 0
      ? []
      : mentionsDto[topicIndex].map(item => ({
          name: item.name.trim(),
          role: item.role ? item.role.trim() : '',
        }));
  if (topicIndex < 0) {
    mentionsDto.forEach(arr =>
      arr.forEach(item => {
        const ind = result.findIndex(v => v.name === item.name && v.role === item.role);
        ind < 0 &&
          result.push({
            name: item.name.trim(),
            role: item.role ? item.role.trim() : '',
          });
      }),
    );
  }

  return result;
};
