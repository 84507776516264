import { dstr2str } from '../../common/utils';

import { IFilterData, TFilterDataFields } from './i-filter';
import { TFilterPredicate } from './i-filter-field';

export const getFilterValue = (filter: IFilterData, filterField: string) => {
  const field = filter[filterField as TFilterDataFields];

  return field ? field.value : undefined;
};

export const getFilterScore = (filter: IFilterData, filterField: string) => {
  const field = filter[filterField as TFilterDataFields];

  return field ? field.score : undefined;
};

export const getFilterPredicate = (filter: IFilterData, filterField: string) => {
  const field = filter[filterField as TFilterDataFields];

  return field ? field.predicate : undefined;
};

export const fixFilterValue = (value: string[]) => (value && value.length > 0 ? [value].flat() : []);

export const hasArrayItem = (array: string[], list: string[], predicate?: TFilterPredicate) => {
  list = fixFilterValue(list);
  if (!list || list.length === 0) return true;
  if (!array) return false;
  array = array.map(l => l.toLowerCase().trim());
  list = list.map(l => l.toLowerCase().trim()).filter(item => item.length > 0);

  let result = false;
  switch (predicate) {
    case 'equal':
      result = false;
      list.forEach(l => (result = result || array.includes(l)));
      break;
    case 'not_equal':
      result = true;
      list.forEach(l => (result = result && !array.includes(l)));
      break;
  }

  return result;
};

export const hasStr = (str: string, list: string[], predicate?: TFilterPredicate) => {
  list = fixFilterValue(list);
  if (!list || list.length === 0) return true;
  if (!str) return false;
  str = str.toLowerCase().trim();
  list = list.map(l => l.toLowerCase().trim()).filter(item => item.length > 0);

  let result = false;

  switch (predicate) {
    case 'contains':
      result = false;
      list.forEach(item => (result = result || str.includes(item)));
      break;
    case 'not_contains':
      result = true;
      list.forEach(item => (result = result && !str.includes(item)));
      break;
    case 'equal':
      result = false;
      list.forEach(item => (result = result || str === item));
      break;
    case 'not_equal':
      result = true;
      list.forEach(item => (result = result && str !== item));
      break;
  }

  return result;
};

export const timeFromTo = (dateStr: string, timeFrom: string, timeTo: string) => {
  if ((!timeFrom || timeFrom.trim().length === 0) && (!timeTo || timeTo.trim().length === 0)) return true;
  if (!dateStr) return false;

  const dstr = dstr2str(dateStr);

  return (!timeFrom || dstr >= timeFrom.trim()) && (!timeTo || dstr <= timeTo.trim());
};
