import { Box, FormGroup, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { IDataSourceEntity } from '../types';

interface IDialogBodyProps {
  source?: IDataSourceEntity;
}

export const DialogBody: React.FC<IDialogBodyProps> = ({ source }) => {
  const { t } = useTranslation();

  return (
    <Box width={600}>
      <FormGroup>
        <>
          <Typography style={{ lineHeight: 1.1 }}>{t('dataSources.dialogs.deleteConfirm')}</Typography>
          <Typography style={{ padding: '30px' }}>{source?.name}</Typography>
        </>
      </FormGroup>
    </Box>
  );
};
