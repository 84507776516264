import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import EditIcon from '@material-ui/icons/Edit';

import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

import { DataGrid, ECommonDialogCloseStatus, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';

import { IWordDictionaryDialogResult, WordDictionaryDialog } from './WordDictionaryDialog';
import { IWordDictionary } from './WordDictionary.types';

import { gAPP_STORE } from '@/app/app-store';

interface IWordDictionaryDialogConfig {
  isOpen: boolean;
  wordDictionary: IWordDictionary | undefined;
}

const defaultWordDictionaryDialogConfig: IWordDictionaryDialogConfig = {
  isOpen: false,
  wordDictionary: undefined,
};

const WordDictionaryList = () => {
  const { t } = useTranslation();

  const [dialogConfig, setDialogConfig] = useState<IWordDictionaryDialogConfig>(defaultWordDictionaryDialogConfig);
  const dictStore = gAPP_STORE.getWordDictionaryStore();

  useEffect(() => {
    gAPP_STORE.getLanguagesStore().getAll();
    gAPP_STORE.viewTranslate && gAPP_STORE.getAutoTranslateLanguagesStore().loadAll(true);
  }, []);

  const handleCloseWordDictionaryDialog = (dialogResult: IWordDictionaryDialogResult) => {
    setDialogConfig(defaultWordDictionaryDialogConfig);

    if (dialogResult.closeStatus === ECommonDialogCloseStatus.OK) {
      if (dialogResult.wordDictionary.id !== -1 && dialogResult.oldValue) {
        dictStore.update(dialogResult.wordDictionary, dialogResult.oldValue);
      } else {
        dictStore.create(dialogResult.wordDictionary);
      }
    }
  };

  const handleCreateWordDictionary = useCallback(() => {
    setDialogConfig({ isOpen: true, wordDictionary: undefined });
  }, []);

  const handleEditWordDictionary = useCallback(() => {
    const selected = gAPP_STORE.getWordDictionaryStore().gridStore.selectedRow;
    if (selected) {
      setDialogConfig({ isOpen: true, wordDictionary: selected });
    }
  }, []);

  const handleDeleteWordDictionary = useCallback(() => {
    const selected = gAPP_STORE.getWordDictionaryStore().gridStore.selectedRow;
    if (selected) {
      if (window.confirm(t('wordDictionary.deleteConfirm'))) {
        gAPP_STORE.getWordDictionaryStore().delete(selected);
      }
    }
  }, [t]);

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
    () => [
      { type: 'text', text: `${t('wordDictionary.title')}`, pr: 1 },
      { type: 'divider', pl: 1 },
      {
        type: 'button',
        text: 'create',
        icon: <AddIcon />,
        onClick: handleCreateWordDictionary,
        pl: 1,
      },
      {
        type: 'button',
        text: 'edit',
        icon: <EditIcon />,
        onClick: handleEditWordDictionary,
        disabled: dictStore.gridStore.selectedRow?.name.length ? false : true,
        pl: 1,
      },
      {
        type: 'button',
        text: 'delete',
        icon: <ClearRoundedIcon />,
        onClick: handleDeleteWordDictionary,
        color: 'red',
        disabled: dictStore.gridStore.selectedRow ? false : true,
        pl: 1,
      },
    ],
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // оставить задизейблиным чтобы отслеживать выбранные поля в таблице
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dictStore.gridStore.selectedRow,
      handleCreateWordDictionary,
      handleDeleteWordDictionary,
      handleEditWordDictionary,
      t,
    ],
  );

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid id="WordDictionarysGrid" cols={dictStore.gridCols} gridStore={dictStore.gridStore} noPaging />
      </Box>

      {dialogConfig.isOpen && (
        <WordDictionaryDialog
          gridStore={dictStore.gridStore}
          onClose={handleCloseWordDictionaryDialog}
          wordDictionary={dialogConfig.wordDictionary}
        />
      )}
    </Panel>
  );
};

export default observer(WordDictionaryList);
