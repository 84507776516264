import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import RecordsStatusLabel from '../records/records-status-label';
import { StatisticsRecordsStore } from '../records/statistics-records-store';

export const AppBarRecordsInfo = observer(() => {
  const { t } = useTranslation();
  const statisticStore = useRef(new StatisticsRecordsStore());
  const [isScrolled, setIsScrolled] = useState(false);
  const statusContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const statStore = statisticStore.current;
    void statStore.entityChangeEventManager.subscribe('<AppBarRecordsInfo> first-uE');

    return () => void statStore.entityChangeEventManager.unsubscribe();
  }, []);

  const checkScroll = () => {
    if (statusContainerRef.current) {
      const isScrollable = statusContainerRef.current.scrollWidth > statusContainerRef.current.clientWidth;
      setIsScrolled(isScrollable);
    }
  };

  useEffect(() => {
    const container = statusContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScroll);
    }

    window.addEventListener('resize', checkScroll);

    checkScroll();

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScroll);
      }
      window.removeEventListener('resize', checkScroll);
    };
  }, [statisticStore.current.statistic.totalPerStatus]);

  return (
    <>
      {statisticStore.current.statistic.totalForAll > 0 && (
        <>
          <Typography style={{ lineHeight: 1.1 }}>{`${t('recordsInDatabase')}:`}</Typography>
          <Box pl={1} />
          <Typography>{statisticStore.current.statistic.totalForAll}</Typography>
        </>
      )}

      {statisticStore.current.statistic.totalPerStatus &&
        statisticStore.current.statistic.totalPerStatus.length > 0 && (
          <>
            <Box
              overflow="hidden"
              display="flex"
              alignItems="center"
              mx={2}
              maxWidth="100%"
              position="relative"
              height="100px"
            >
              {isScrolled && (
                <Box
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '1px',
                    background: '#fff',
                    opacity: '0.24',
                  }}
                />
              )}

              <div
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  maxWidth: '100%',
                  overflow: 'auto',
                  whiteSpace: 'nowrap',
                }}
                ref={statusContainerRef}
              >
                {statisticStore.current.statistic.totalPerStatus
                  .slice()
                  .sort((a, b) => a.status - b.status)
                  .map((tc, index) => (
                    <RecordsStatusLabel key={index} status={tc.status} total={tc.total} />
                  ))}
              </div>
              {isScrolled && (
                <Box
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '1px',
                    background: '#fff',
                    opacity: '0.24',
                  }}
                />
              )}
            </Box>
          </>
        )}
    </>
  );
});
