import { makeAutoObservable } from 'mobx';

import { IRecordsStatistic } from './types';

import { EntitySubscriptionIdManager } from '@/services/events/entity-subscription-id-manager';
import { IEntityChangeEvent } from '@/types/events';
import { EntityChangeEventActions, EntityChangeEventTypes } from '@/services/events/types';

export class StatisticsRecordsStore {
  entityChangeEventManager: EntitySubscriptionIdManager<number, IRecordsStatistic, IRecordsStatistic>;

  statistic: IRecordsStatistic = {
    id: 0,
    totalForAll: 0,
    totalPerStatus: [],
  };

  constructor() {
    console.log('@@ StatisticsRecordsStore.constructor');
    makeAutoObservable(this, undefined, { autoBind: true });

    this.entityChangeEventManager = new EntitySubscriptionIdManager<number, IRecordsStatistic, IRecordsStatistic>(
      async (orig, event) => await this.onStatisticEvent(orig, event),
      [{ action: EntityChangeEventActions.UPDATE, type: EntityChangeEventTypes.RECORDS_STATS }],
    );

    this.entityChangeEventManager.setData([this.statistic]);
  }

  async onStatisticEvent(orig: IRecordsStatistic, event: IEntityChangeEvent<IRecordsStatistic>) {
    if (JSON.stringify(this.statistic) !== JSON.stringify(event.value)) {
      this.statistic = event.value;
    }

    return await Promise.resolve();
  }
}
