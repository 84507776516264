export interface IDataSourceEntity {
  id: number;
  name: string;
  path: string;
  settings: {
    channelType: null | string;
    sharedFolderCreds: null;
    groups: number[] | null;
    language: string | null;
    wordDictionaries: null;
    preprocessingModeId: null;
    meetingTitleString: string | null;
    hotlist: null;
    splitRanges: null;
    identThreshold: null;
    speakerChangePostprocessingRadiusSec: null;
    maxFileSizeInBytes: number;
    maxUploadFilesPerDay: number;
    ciscoNamespace: null | string;
    spaceObjectId: null | string;
    emailNotifyListeners: number[];
  };
}

export class CSource implements IDataSourceEntity {
  id = -1;
  name = '';
  path = '';
  settings = {
    channelType: 'CISCO',
    sharedFolderCreds: null,
    groups: null,
    language: null,
    wordDictionaries: null,
    preprocessingModeId: null,
    meetingTitleString: null,
    hotlist: null,
    splitRanges: null,
    identThreshold: null,
    speakerChangePostprocessingRadiusSec: null,
    maxFileSizeInBytes: 2147483647,
    maxUploadFilesPerDay: 100000,
    ciscoNamespace: null,
    spaceObjectId: null,
    emailNotifyListeners: [],
  };
}
