import { makeAutoObservable, runInAction } from 'mobx';

import { toast } from 'react-toastify';

import { IDataSourceEntity } from './types';

import { sourcesGridColumns } from './data-sources-columns';

import { DataGridStore, IDataGridStoreData, IDataGridStoreState } from '@/react-ui-kit/src';
import { sourcesService } from '@/services/data-sources/data-sources';
import { BackendError, HandleErrorTranslatedMessage } from '@/services/types';

interface ISourcesData {
  sources: IDataSourceEntity[];
  hasNextPage: boolean;
}

const sourcesInitialState: ISourcesData = {
  sources: [],
  hasNextPage: false,
};

export class DataSourcesStore {
  data: ISourcesData = sourcesInitialState;

  gridStore: DataGridStore<IDataSourceEntity>;
  gridCols = sourcesGridColumns;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.gridStore = new DataGridStore<IDataSourceEntity>('DataSources', this.gridCols, {
      dataProvider: this.dataProvider,
    });
  }

  dataProvider = async (state: IDataGridStoreState): Promise<IDataGridStoreData<IDataSourceEntity>> => {
    await this.getAll();

    return { data: this.data.sources, total: this.data.sources.length, hasNextPage: this.data.hasNextPage };
  };

  private getAll = async () => {
    const result = await sourcesService.getAllSources();

    runInAction(() => {
      if (result) {
        this.data.sources = result;
        this.data.hasNextPage = false;
      } else {
        this.data.sources = [];
      }
    });
  };

  /**
   * Create new Source
   */
  create = async (source: IDataSourceEntity) => {
    try {
      await sourcesService.createSource(source);
      await this.gridStore.reload();
    } catch (error) {
      this.showError(error);
    }
  };

  /**
   * Delete Source
   */

  delete = async (source: IDataSourceEntity) => {
    try {
      await sourcesService.deleteSourceById(source.id);
      this.gridStore.reload();
    } catch (error) {
      this.showError(error);
    }
  };

  /**
   * Update Source
   */

  update = async (source: IDataSourceEntity) => {
    try {
      await sourcesService.updateSource(source);
      await this.gridStore.reload();
    } catch (error) {
      this.showError(error);
    }
  };

  showError(error: unknown) {
    if (error instanceof BackendError) {
      toast.error(HandleErrorTranslatedMessage(error));
    } else {
      toast.error(`Server error: ${JSON.stringify(error)}`);
    }
  }
}
