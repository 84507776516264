import { Box, Button, Chip, Tooltip, Typography, useTheme, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Add } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';

import { DialogStore } from '../dialog.store';

import { Stack } from './Stack';

import { gAPP_STORE } from '@/app/app-store';

export interface IItemFiles {
  data: DialogStore;
}

const useStyles = makeStyles({
  requiredLabel: {
    position: 'relative',
    '&::after': {
      content: '" *"',
      color: 'red',
      marginLeft: '0.2em',
    },
  },
});

export const ItemFiles: FC<IItemFiles> = observer(({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  //const [fl, setFl] = useState(data.fileNames);

  const blockAlertExceedNumberFiles = useMemo(
    () => (
      <Alert severity="warning" variant="filled" style={{ fontSize: '1em', marginTop: '1ex' }}>
        {t('numberOfFilesWarning2', { max_uploaded_files: gAPP_STORE.maxFilesToUpload })}
      </Alert>
    ),
    [t],
  );

  const blockAlertHaveFilesWithErrors = useMemo(
    () => (
      <Alert severity="warning" variant="filled" style={{ fontSize: '1em', marginTop: '1ex' }}>
        {t('uploadForm.warningFilesWithErrors')}
      </Alert>
    ),
    [t],
  );

  const handleFileCapture = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const pureFiles = event.target.files;
      const pureFilesArr = pureFiles && Array.from(pureFiles);
      const allowedExtList = new Set(gAPP_STORE.recordExtensionsForLoading);
      const fileList = pureFilesArr?.filter(file => {
        const fileExt = file && file?.name?.toLowerCase().split('.').at(-1);

        return fileExt && allowedExtList.has(`.${fileExt}`);
      });
      if (fileList?.length !== pureFilesArr?.length) {
        toast(t('records.selectedWrongFiles'), { type: 'error' });
      }

      if (fileList?.length) {
        data.addFiles(fileList);
        const firstFile = fileList[0];
        if (firstFile.name.endsWith('.zip') && fileList.length === 1) {
          if (firstFile.name.length > 60) {
            let lines = firstFile.name.split('');
            lines = lines.slice(0, 57);
            lines.push('...');
            const trimmedText = lines.join('');
            data.setCaseId(trimmedText);
            data.setMettingTitle(trimmedText);
          } else {
            data.setCaseId(firstFile.name);
            data.setMettingTitle(firstFile.name);
          }
        }
      }
    },
    [data, t],
  );

  const labelAndButton = useMemo(
    () => (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography className={classes.requiredLabel}>{t('uploadForm.files')}</Typography>
        <Tooltip
          title={
            <>
              <Typography>{t('uploadForm.files')}</Typography>
              <Typography style={{ color: '#FFFFFFA3' }}>{t('uploadForm.descriptionGroupFormFiles')}</Typography>
            </>
          }
        >
          <InfoIcon color="disabled" />
        </Tooltip>
        <Button
          size="small"
          component="label"
          style={{
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            outlineColor: theme.palette.primary.main,
            textDecorationColor: theme.palette.primary.main,
          }}
        >
          <Add />
          <input
            value={''}
            id="inputFileId"
            type="file"
            onChange={handleFileCapture}
            multiple
            hidden
            accept={gAPP_STORE.recordExtensionsForLoading.join(',')}
          />
        </Button>
      </Stack>
    ),
    [handleFileCapture, t, classes.requiredLabel, theme.palette.primary.main],
  );

  const handleExcludeFile = useCallback(
    (index: number) => {
      data.excludeFile(index);
    },
    [data],
  );

  const btnClear = () => {
    if (data.fileNames.length === 0) {
      return <div></div>;
    } else {
      return (
        <button
          onClick={() => {
            data.deleteAllFile();
          }}
          className="MuiButtonBase-root MuiIconButton-root"
          tabIndex={-1}
          type="button"
          aria-label="Очистить поле"
          title="Очистить поле"
        >
          <span className="MuiIconButton-label">
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </span>
        </button>
      );
    }
  };

  const colors = useMemo(
    () => [theme.palette.success.main, theme.palette.grey[200], theme.palette.error.main],
    [theme.palette.error.main, theme.palette.grey, theme.palette.success.main],
  );

  const listFiles = (
    <Box
      display={'flex'}
      flexDirection={'row'}
      minWidth={'100%'}
      border={`1px solid ${theme.palette.grey[200]}`}
      borderRadius="0.5em"
    >
      <Stack
        flexWrap="wrap"
        direction="row"
        alignItems={'center'}
        p={1}
        borderRadius={1}
        minHeight={'2.2em'}
        gap={0.5}
        minWidth={'90%'}
        maxWidth={'90%'}
      >
        {data.fileNames.map((item, index) => {
          return (
            <Chip
              key={index}
              color="primary"
              style={{ backgroundColor: colors[item.color] }}
              size="small"
              label={item.name}
              onDelete={() => handleExcludeFile(item.fileIndex)}
            />
          );
        })}
      </Stack>
      <Box width={'10%'} display={'flex'} alignItems="center" justifyContent={'space-around'}>
        {btnClear()}
      </Box>
    </Box>
  );

  return (
    <>
      <Stack spacing={2}>
        <Stack spacing={0}>
          {labelAndButton}
          {listFiles}
        </Stack>
      </Stack>

      {data.isFileCountExceedLimit && blockAlertExceedNumberFiles}
      {data.haveFilesWithError && blockAlertHaveFilesWithErrors}
    </>
  );
});
