import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';

import { ILabel } from '../../types';

import { ItemComment } from './itemComment';
import { ItemLabelTypesComboBox } from './itemLabelTypesComboBox';

export interface IItemLabelEditComment {
  label: ILabel;
  handleSave: (comment: string) => void;
}

export const ItemLabelEditComment = observer(({ label, handleSave }: IItemLabelEditComment) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState<string>(label.comment ?? '');
  useEffect(() => {
    setComment(label.comment ?? '');
  }, [label]);

  const hSave = useCallback(() => {
    handleSave(comment);
  }, [comment, handleSave]);

  const btnSave = (
    <Button
      onClick={hSave}
      variant="contained"
      color={'primary'}
      style={{ margin: '1em  0 1em 0' }}
      data-autotest="ItemLabelEditCommentButton"
    >
      {t('labelForm.saveComment')}
    </Button>
  );

  return (
    <div id="ItemLabelEditComment" style={{ width: '100%', padding: '5px' }} data-autotest="ItemLabelEditComment">
      <ItemLabelTypesComboBox defaultLabel={label} />
      <ItemComment text={comment} setComment={setComment} focus={true} />
      {btnSave}
    </div>
  );
});
