import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ISourceDialogResult } from '../data-source-create-dialog/data-source-create-dialog';

import { CSource, IDataSourceEntity } from '../types';

import { DialogBody } from './dialog-body';

import { CommonDialog, CommonDialogUiStore } from '@/react-ui-kit/src';

interface IDialogDeleteSourceProps {
  open: boolean;
  source?: IDataSourceEntity;
  onClose: (dialogResult: ISourceDialogResult) => void;
}

const DialogDeleteSource: React.FC<IDialogDeleteSourceProps> = ({ open, source, onClose }) => {
  const { t } = useTranslation();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());
  const [dialogResult, setDialogResult] = useState<ISourceDialogResult>({
    source: !!source ? { ...source } : new CSource(),
    closeStatus: 0,
  });

  useEffect(() => {
    if (source) {
      setDialogResult(prevDialogResult => ({ ...prevDialogResult, source: { ...source } }));
    }
  }, [source]);

  const handleClose = (status: number) => {
    const newDilogResult = {
      ...dialogResult,
      closeStatus: status,
    };
    setDialogResult(newDilogResult);
    onClose(newDilogResult);
  };

  return (
    <CommonDialog
      title={t('dataSources.dialogs.deleteTitle')}
      open={open}
      onClose={handleClose}
      contentComponent={<DialogBody source={source} />}
      autotestId={'deleteSource'}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default DialogDeleteSource;
