import { v4 as uuidv4 } from 'uuid';

import ReconnectingEventSource from 'reconnecting-eventsource';

import { BackendService } from '../backend-service';

import { gAPP_STORE } from '@/app/app-store';

export interface IEntityChangeSubscription {
  type: string;
  action: string;
}

export interface ISubscription {
  id: string;
  unsubscribe: (delayUpdate?: boolean) => void;
}

export type TSubscriptionInfo = {
  params: IEntityChangeSubscription;
  cb: (e: MessageEvent<string>) => void;
};
export class EntityChangeEventsService {
  eventSource?: EventSource;
  filters: Map<string, IEntityChangeSubscription> = new Map();
  subscriptions: Map<string, TSubscriptionInfo> = new Map();
  id = uuidv4();

  private startAwaiter?: Promise<boolean> = undefined;

  private start() {
    if (this.startAwaiter) return this.startAwaiter;

    const es = new ReconnectingEventSource(`/api/v1/events/entity-changes/${this.id}?${this.getParams()}`, {
      max_retry_time: 1800000,
    });
    es.onerror = () => {
      console.log('ERRR');
    };

    this.startAwaiter = new Promise(
      resolve =>
        (es.onopen = () => {
          this.eventSource = es;
          resolve(true);
        }),
    );

    return this.startAwaiter;
  }

  private getParams() {
    const filter = {
      type: Array.from(this.filters, ([name, value]) => value.type).filter(
        (item, pos, self) => self.indexOf(item) === pos,
      ),
      action: Array.from(this.filters, ([name, value]) => value.action).filter(
        (item, pos, self) => self.indexOf(item) === pos,
      ),
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new URLSearchParams(filter as any);
  }

  private async update() {
    await BackendService.get(`events/entity-changes/update/${this.id}`, this.getParams().toString());
  }

  public async updateForce() {
    if (this.eventSource && !gAPP_STORE.loginStore.isSignedIn()) {
      return;
    }
    if (!this.eventSource) {
      await this.start(); //block until first event
    } else {
      await this.update();
    }
  }

  public forceSignOut() {
    this.id = uuidv4();
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = undefined;
    }
    this.startAwaiter = undefined;
  }

  private makeEvent(params: IEntityChangeSubscription): string {
    return `${params.action}:${params.type}`;
  }
  public async subscribe(
    params: IEntityChangeSubscription,
    cb: (e: MessageEvent<string>) => void,
    delayUpdate = false,
  ) {
    const event = this.makeEvent(params);
    const id = uuidv4();
    this.filters.set(id, params);

    if (!this.eventSource) {
      await this.start(); //block until first event
    } else {
      if (!delayUpdate) {
        await this.update();
      }
    }

    this.eventSource?.addEventListener(event, cb);
    this.subscriptions.set(id, { params, cb });

    return {
      id: id,
      unsubscribe: delayUpdate => this.unsubscribe(id, delayUpdate),
    } as ISubscription;
  }

  public unsubscribe(id: string, delayUpdate = false) {
    const subscription = this.subscriptions.get(id);
    if (subscription) {
      const event = this.makeEvent(subscription.params);
      this.eventSource?.removeEventListener(event, subscription.cb);
      this.filters.delete(id);
      this.subscriptions.delete(id);
    }
  }
}
