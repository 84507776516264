import appColors from '@/app/app-colors';

export function creatMarkerHtmlElement(text: string, isPhrase: boolean) {
  const WIDTH = 50;
  const PADDING = 5;

  const containerElement = document.createElement('div');
  const htmlElement = document.createElement('div');
  const htmlHiddenElement = document.createElement('div');

  containerElement.style.width = 'fit-content';
  containerElement.style.borderRadius = '5px';

  htmlHiddenElement.innerText = text;
  htmlHiddenElement.style.position = 'absolute';
  htmlHiddenElement.style.opacity = '0';
  htmlHiddenElement.style.zIndex = '-1';
  htmlHiddenElement.style.whiteSpace = 'nowrap';

  htmlElement.innerText = text;
  htmlElement.style.display = 'inline-block';
  htmlElement.style.width = '100%';
  htmlElement.style.color = 'white';
  htmlElement.style.paddingRight = '3px';
  htmlElement.style.paddingLeft = '3px';
  htmlElement.style.margin = '1px';
  htmlElement.style.borderRadius = '5px';
  htmlElement.style.backgroundColor = appColors.red;

  if (isPhrase) {
    containerElement.style.width = `${WIDTH}px`;
    htmlElement.style.transition = 'width 0.1s ease-in';
    htmlElement.style.whiteSpace = 'nowrap';
    htmlElement.style.overflow = 'hidden';
    htmlElement.style.width = `${WIDTH - PADDING}px`;
    htmlElement.style.textOverflow = 'ellipsis';

    containerElement.onmouseenter = () => {
      containerElement.style.width = '100%';
      htmlElement.style.width = `${htmlHiddenElement.clientWidth + PADDING}px`;
      containerElement.style.zIndex = '10';
      containerElement.style.position = 'relative';
      htmlElement.style.zIndex = '10';
    };

    containerElement.onmouseleave = () => {
      containerElement.style.width = `${WIDTH}px`;
      htmlElement.style.width = `${WIDTH - PADDING}px`;
      containerElement.style.zIndex = '3';
      htmlElement.style.zIndex = '3';
      containerElement.style.position = 'static';
    };
  }

  containerElement.appendChild(htmlElement);
  containerElement.appendChild(htmlHiddenElement);

  return containerElement;
}
