import { DataGridStore, IDataGridStoreData, IDataGridStoreState } from '@uk';
import { action, makeAutoObservable } from 'mobx';

import { toast } from 'react-toastify';

import i18n from 'i18next';

import { AutoTranslateGridColumns } from './auto-translate-grid.descriptor';

import { AutoTranslateService, ITranslateSettingLoaded } from './service/auto-translate-service';

import { ITranslateDirection, ITranslateSetting } from './types';

import { BackendError } from '@/services/types';
export class AutoTranslateLanguagesStore {
  translateDirections: ITranslateDirection[] = [];
  autoTranslateLanguages: string[] = [];

  gridStore: DataGridStore<ITranslateSetting>;
  gridCols = AutoTranslateGridColumns;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    const dataProvider = async (state: IDataGridStoreState): Promise<IDataGridStoreData<ITranslateSetting>> => {
      const data = await this.getTranslateSettings();

      return { data: data, total: data.length, hasNextPage: false };
    };

    this.gridStore = new DataGridStore<ITranslateSetting>('AutoTranslateSettings', this.gridCols, {
      dataProvider: dataProvider,
    });
  }

  @action
  setTranslateDirection = (translateDirections: ITranslateDirection[]) => {
    this.translateDirections = translateDirections;
  };

  @action
  setAutoTranslateLanguages = (autoTranslateLanguages: string[]) => {
    this.autoTranslateLanguages = autoTranslateLanguages;
  };

  loadAll = async (noToast?: boolean) => {
    try {
      if (this.translateDirections === null || this.translateDirections.length === 0) {
        const translationDirections = await AutoTranslateService.getTranslateDirections();
        this.setTranslateDirection(translationDirections.directions);
        this.setAutoTranslateLanguages(this.buildAutoTranslateLanguages());
      }
      this.gridStore.reload();
    } catch (error) {
      if (error instanceof BackendError) {
        !noToast && toast.error(i18n.t('settings.autoTranslateError'));
      } else {
        !noToast && toast.error(i18n.t('BadRequest'));
        throw error;
      }
    }
  };

  getTranslateSettings = async () => {
    return await AutoTranslateService.getTranslateSettings();
  };

  update = async (translateSetting: ITranslateSetting, oldValue: ITranslateSetting) => {
    try {
      const result: ITranslateSetting = await AutoTranslateService.updateTranslate(translateSetting.value);
      oldValue.value = translateSetting.value;
      oldValue.modifyedDate = result.modifyedDate;
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };

  add = async (translateSetting: ITranslateSetting) => {
    try {
      const result: ITranslateSettingLoaded = await AutoTranslateService.add(translateSetting.value);

      const setting: ITranslateSetting = { ...result, value: JSON.parse(result.value) };
      this.gridStore.appendRow(setting);
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };

  private buildAutoTranslateLanguages = (): string[] => {
    return Array.from(new Set(this.translateDirections.map(direct => direct.to)));
  };

  delete = async (translateSetting: ITranslateSetting) => {
    await AutoTranslateService.delete();
    this.gridStore.removeRow(translateSetting);
  };
}
