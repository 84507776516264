import { IFilterData } from '../filter/i-filter';
import { IFilterField, TFilterPredicate } from '../filter/i-filter-field';

import { gAPP_STORE } from '@/app/app-store';

const listFieldPredicates: TFilterPredicate[] = ['equal', 'not_equal'];
const equalOnly: TFilterPredicate[] = ['equal'];

export const evenLogDefaultFilterDescriptor: IFilterField[] = [
  {
    id: 'eventType',
    title: 'eventLog.eventType',
    type: 'list',
    value: [],
    multiple: false,
  },
  { id: 'loadFrom', title: 'eventLog.dateFrom', type: 'datetime', value: '' },
  { id: 'loadTo', title: 'eventLog.dateTo', type: 'datetime', value: '' },
  {
    id: 'userId',
    title: 'eventLog.userId',
    type: 'list',
    value: [],
    predicate: 'equal',
    predicates: listFieldPredicates,
    multiple: true,
  },
  {
    id: 'actionType',
    title: 'eventLog.actionType',
    type: 'list',
    value: [],
    predicates: listFieldPredicates,
    predicate: 'equal',
    multiple: true,
  },
];

const variantFilterFieldDescriptor = new Map<string, IFilterField>();
const getVariantFilterFieldDescriptor = () => {
  if (variantFilterFieldDescriptor.size === 0) {
    variantFilterFieldDescriptor.set('login', {
      id: 'login',
      title: 'eventLog.login',
      type: 'list',
      value: [],
      predicate: 'equal',
      predicates: equalOnly,
      multiple: true,
    });
    variantFilterFieldDescriptor.set('privilege', {
      id: 'privilege',
      title: 'eventLog.privilege',
      type: 'text',
      value: [],
      predicate: 'equal',
      multiple: true,
    });
    variantFilterFieldDescriptor.set('group_name', {
      id: 'group_name',
      title: 'eventLog.group_name',
      type: 'list',
      value: [],
      predicate: 'equal',
      predicates: equalOnly,
      multiple: true,
    });
    variantFilterFieldDescriptor.set('groups_names', {
      id: 'groups_names',
      title: 'eventLog.group_name',
      type: 'list',
      value: [],
      predicate: 'equal',
      predicates: equalOnly,
      multiple: true,
    });
    variantFilterFieldDescriptor.set('topic_name', {
      id: 'topic_name',
      title: 'eventLog.topic_name',
      type: 'list',
      value: [],
      predicate: 'equal',
      predicates: equalOnly,
      multiple: true,
    });
    //is_kws	is_auto_asr
    variantFilterFieldDescriptor.set('topic_type', {
      id: 'topic_type',
      title: 'eventLog.topic_type',
      type: 'list',
      value: [],
      predicate: 'equal',
      multiple: true,
    });
    variantFilterFieldDescriptor.set('template_name', {
      id: 'template_name',
      title: 'eventLog.template_name',
      type: 'list',
      value: [],
      predicate: 'equal',
      predicates: equalOnly,
      multiple: true,
    });
    variantFilterFieldDescriptor.set('format', {
      id: 'format',
      title: 'eventLog.format',
      type: 'list',
      value: [],
      predicate: 'equal',
      multiple: true,
    });
    variantFilterFieldDescriptor.set('channel_name', {
      id: 'channel_name',
      title: 'eventLog.channel_name',
      type: 'list',
      value: [],
      predicate: 'equal',
      predicates: listFieldPredicates,
      multiple: true,
    });

    variantFilterFieldDescriptor.set('filename', {
      id: 'filename',
      title: 'eventLog.filename',
      type: 'text',
      value: [],
      predicate: 'equal',
      multiple: true,
    });
    variantFilterFieldDescriptor.set('dictor_name', {
      id: 'dictor_name',
      title: 'eventLog.dictor_name',
      type: 'text',
      value: [],
      predicate: 'equal',
      multiple: true,
    });
    variantFilterFieldDescriptor.set('dictor_surname', {
      id: 'dictor_surname',
      title: 'eventLog.dictor_surname',
      type: 'text',
      value: [],
      predicate: 'equal',
      multiple: true,
    });
    variantFilterFieldDescriptor.set('record_name', {
      id: 'record_name',
      title: !gAPP_STORE.meetingTitleColumnName
        ? 'records.meetingTitle'
        : (gAPP_STORE.meetingTitleColumnName as string),
      type: 'text',
      value: [],
      predicate: 'equal',
      multiple: true,
    });
    variantFilterFieldDescriptor.set('processing_method', {
      id: 'processing_method',
      title: 'eventLog.processing_method',
      type: 'list',
      value: [],
      predicate: 'equal',
      multiple: true,
    });
  }

  return variantFilterFieldDescriptor;
};
export const getFilterLinkedEventLog = (params: string[]): IFilterField[] => {
  const newLinkedFilter = new Set<IFilterField>();
  params.forEach(p => {
    const field = getVariantFilterFieldDescriptor().get(p);
    if (field !== undefined) {
      newLinkedFilter.add(field as IFilterField);
    }
  });
  if (params.includes('topic_name')) {
    newLinkedFilter.add(getVariantFilterFieldDescriptor().get('topic_type') as IFilterField);
  }

  return Array.from(newLinkedFilter);
};

export const convertFilterFieldToData = (filterField: IFilterField[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filter: any = {};

  filterField.forEach(fd => {
    filter[fd.id] = { value: fd.value, predicate: fd.predicate };
  });

  return filter as IFilterData;
};
