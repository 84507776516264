import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, useTheme } from '@material-ui/core';

import { ILabel } from '../../types';
import { Stack } from '../UploadFiles/items/Stack';

import { ILabelsDialogResult } from './DialogChangeLabels';
import { ItemLabelAddComment } from './itemLabelAddComment';
import { ItemLabelEditComment } from './itemLabelEditComment';
import { LabelChip } from './LabelChip';

export interface IDialogLabelBody {
  data: ILabelsDialogResult;
  setOkEnabled(): void;
}
//TODO: избавиться от мутаций в JSX, простановка данных в стейт через мутацию недопустима, работать через setData() https://react.dev/learn/updating-objects-in-state#treat-state-as-read-only
export const DialogLabelsBody = observer(({ data, setOkEnabled }: IDialogLabelBody) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [listLabels, setListLabels] = useState<ILabel[]>(data.oldLabels.length > 0 ? [...data.oldLabels] : []);
  const [selected, setSelected] = useState<number | undefined>(undefined);

  const handleAdd = useCallback(
    (label: ILabel, comment: string) => {
      const newLabel: ILabel = { ...label, comment };
      const labels = [...listLabels, newLabel];
      data.addLabels.push(newLabel);
      setOkEnabled();
      setListLabels(labels);
    },
    [data, listLabels, setOkEnabled],
  );

  const handleSave = useCallback(
    (comment: string) => {
      if (selected === undefined) return;

      const label = listLabels[selected];
      const newComment = comment.trim().length === 0 ? undefined : comment.trim();
      if (newComment !== label.comment) {
        if (
          data.addLabels.findIndex(lbl => lbl.id === label.id) >= 0 ||
          data.updateLabels.findIndex(lbl => lbl.id === label.id) >= 0
        ) {
          label.comment = newComment;
        } else {
          const newLabel = { ...label, comment: newComment };
          listLabels[selected] = newLabel;
          data.updateLabels.push(newLabel);
        }
      }

      setSelected(undefined);
      setOkEnabled();
    },
    [data.addLabels, data.updateLabels, listLabels, selected, setOkEnabled],
  );

  const hDelete = useCallback(
    (index?: number) => {
      if (index === undefined) return;
      setSelected(undefined);
      const delLabel = listLabels[index];
      const addIndex = data.addLabels.findIndex(lbl => lbl.typeId === delLabel.typeId);
      if (addIndex >= 0) {
        data.addLabels.splice(addIndex, 1);
      } else {
        data.delLabels.push(delLabel);
      }
      const newLabels = listLabels.filter((_, k) => k !== index);
      setOkEnabled();
      setListLabels(newLabels);
    },
    [data, listLabels, setOkEnabled],
  );

  const hClick = useCallback((index?: number) => {
    setSelected(index);
  }, []);

  const labelOfResult = useMemo(
    () => (
      <Stack direction="row" alignItems="center" spacing={0}>
        <Typography>{t('labelForm.labelListPreview')}</Typography>
      </Stack>
    ),
    [t],
  );

  const itemResult = (
    <Stack spacing={2} data-autotest="DialogChangelabelsBodyResultField">
      <Stack spacing={0}>
        <Stack
          flexWrap="wrap"
          direction="row"
          alignItems={'center'}
          p={1}
          border={`1px solid ${theme.palette.grey[200]}`}
          borderRadius={1}
          minHeight={'2.2em'}
          gap={0.5}
        >
          {listLabels.map((label, index) => (
            <LabelChip
              key={label.typeId ? label.typeId : label.id}
              label={label}
              index={index}
              onDelete={hDelete}
              onClick={data.recordIds.length === 1 && !label.isDeleted ? hClick : undefined}
              selected={index === selected}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );

  const blockAddEdit = useCallback(() => {
    return selected !== undefined ? (
      <ItemLabelEditComment label={listLabels[selected]} handleSave={handleSave} />
    ) : (
      <ItemLabelAddComment
        handleAdd={handleAdd}
        isLimitReached={data.addLabels.length >= 10}
        selectedTypeIds={listLabels.map(label => label.typeId)}
      />
    );
  }, [data.addLabels.length, handleAdd, handleSave, listLabels, selected]);

  return (
    <Box style={{ padding: '0' }} data-autotest="DialogChangeLabelsBody">
      <Box width={600} style={{ gap: '3em' }}>
        {blockAddEdit()}
        {labelOfResult}
        {itemResult}
      </Box>
    </Box>
  );
});
