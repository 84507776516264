import { Checkbox, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useCallback } from "react";
import styles from './dataGrid.module.scss';
import { IDataGridCellProps } from "./types";

// const copyToClipboard = (text: string) => {
//     const input = document.createElement('input');
//     input.setAttribute('value', text);
//     document.body.appendChild(input);
//     input.select();
//     const result = document.execCommand('copy');
//     document.body.removeChild(input);
//     return result;
// };


const DataGridCell: React.FC<IDataGridCellProps> = ({ row, isSelectedRow, colIndex, rowIndex, col, fixedRowHeight, SIZE, gridStore, isNeedTooltip, handleCheckRow }) => {
    const cellContentClass = isNeedTooltip ? styles.tooltipsCellContent : styles.cellContent;
    const cellContentIsOverflowClass = isNeedTooltip ? styles.tooltipsCellContentIsOverflow : styles.cellContent;

    let value = col.id ? row[col.id] : '';
    if (col.getData) {
        value = col.getData(row);
    }
    if (col.renderer) {
        value = col.renderer(row, value, isSelectedRow);
    } else {
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }
    }

    const getGlobalRowIndex = useCallback((rowIndex: number) => gridStore.currentPage * gridStore.rowsOnPage + rowIndex, [gridStore.currentPage, gridStore.rowsOnPage]);

    const handleCheckboxOnClick = useCallback((event: any) => {
        event.stopPropagation();
        // event.preventDefault();
        // forceUpdate();
    }, []);

    return (
        <TableCell
            data-autotest={'tableCell-' + colIndex}
            key={colIndex}
            align={col.align}
            // width={colLayout.width || col.width || 'auto'}
            className={styles.cell}
            style={{ maxWidth: col.hasCheck ? 1 : undefined }}
        >
            <div
                className={!col.isOverflow ? (
                        clsx(cellContentClass, { [styles.autotestNoIdWarn]: !(col.autotestId || col.id) })) : 
                        clsx(cellContentIsOverflowClass, { [styles.autotestNoIdWarn]: !(col.autotestId || col.id) }
                    )
                }
                style={{
                    height: !fixedRowHeight ? 'auto' : '1.2em',
                    justifyContent: col.align,
                    // width: colWidth,
                    textAlign: col.align,
                    maxWidth:'350px',
                }}
                // onClick={() => copyToClipboard(value)}
                data-autotest={
                    'grid-col-value-' +
                    (col.id ?? col.name ?? '') +
                    '-' +
                    (col.autotestId || col.id) +
                    '-' +
                    colIndex +
                    rowIndex
                }
            >
                {col.hasCheck ? (
                    <Checkbox
                        color="primary"
                        size={SIZE}
                        style={{ padding: 0 }}
                        inputProps={
                            {
                                'data-autotest':
                                    'grid-col-chb-' +
                                    (col.id ?? col.name ?? '') +
                                    '-' +
                                    (col.autotestId || '') +
                                    '-' +
                                    colIndex +
                                    rowIndex,
                            } as any
                        }
                        checked={gridStore.checkedRows.includes(getGlobalRowIndex(rowIndex))}
                        // onMouseDown={handleCheckboxOnClick}
                        onClick={handleCheckboxOnClick}
                        onChange={e => handleCheckRow(row, rowIndex, e)}
                    />
                ) : (
                    value
                )}
            </div>
        </TableCell>
    );
}

export default observer(DataGridCell)