import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Chip, FormControl, FormGroup, makeStyles, TextField } from '@material-ui/core';

import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';

import { CSource, IDataSourceEntity } from '../types';

import { dialogDataSourceRestrictions } from '@/common/constants';
import { gAPP_STORE } from '@/app/app-store';
import { checkTrim } from '@/components/records/utils/checkTrim';

interface IDialogBodyProps {
  source?: IDataSourceEntity;
  onChange: (source: IDataSourceEntity) => void;
  setIsValid: (v: boolean) => void;
}

const useStyles = makeStyles({
  asterisk: {
    color: 'red',
  },
  requiredLabel: {
    position: 'relative',
    '&:after': {
      content: '"*"',
      color: 'red',
      marginLeft: '4px',
    },
  },
});

export const DataSourceDialogBody: React.FC<IDialogBodyProps> = ({ onChange, source, setIsValid }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const localState = useRef<IDataSourceEntity>(source ? { ...source } : new CSource());

  const trimTest = useMemo(
    () => ({ name: 'checkTrim', test: checkTrim, message: t('dataSources.errors.trimError') }),
    [t],
  );

  const formSchema = yup.object({
    name: yup
      .string()
      .required(t('dataSources.errors.requiredField'))
      .max(dialogDataSourceRestrictions.MAX_NAME_LENGTH, t('dataSources.errors.lengthError'))
      .min(dialogDataSourceRestrictions.MIN_NAME_LENGTH, t('dataSources.errors.lengthError'))
      .test(trimTest),
    groups: yup
      .array()
      .of(yup.number())
      .required(t('dataSources.errors.requiredField'))
      .max(dialogDataSourceRestrictions.MAX_GROUPS_COUNT, t('dataSources.errors.lengthError'))
      .min(dialogDataSourceRestrictions.MIN_GROUPS_COUNT, t('dataSources.errors.lengthError'))
      .test('max-10-items', 'Groups cannot exceed 10', value => {
        if (value && value.length > dialogDataSourceRestrictions.MAX_GROUPS_COUNT) {
          toast.warn(t('users.maxGroups', { max_groups: dialogDataSourceRestrictions.MAX_GROUPS_COUNT }));
          const trimmedValue = value.slice(0, dialogDataSourceRestrictions.MAX_GROUPS_COUNT); // Обрезаем массив до 10 элементов
          setValue('groups', trimmedValue); // Обновляем значение в форме

          return true;
        }

        return true;
      }),
    ciscoName: yup
      .string()
      .required(t('dataSources.errors.requiredField'))
      .max(dialogDataSourceRestrictions.MAX_CISCO_NAME_LENGTH, t('dataSources.errors.lengthError'))
      .test(trimTest),
    spaceID: yup
      .string()
      .required(t('dataSources.errors.requiredField'))
      .max(dialogDataSourceRestrictions.MAX_SPACE_ID_LENGTH, t('dataSources.errors.lengthError'))
      .test(trimTest),
    emailNotifyListeners: yup
      .array()
      .of(yup.number())
      .required(t('dataSources.errors.requiredField'))
      .max(dialogDataSourceRestrictions.MAX_USERS_COUNT, t('dataSources.errors.lengthError'))
      .min(dialogDataSourceRestrictions.MIN_USERS_COUNT)
      .test('max-10-items', 'Groups cannot exceed 10', value => {
        if (value && value.length > dialogDataSourceRestrictions.MAX_USERS_COUNT) {
          toast.warn(t('dataSources.errors.maxUsers', { max_users: dialogDataSourceRestrictions.MAX_USERS_COUNT }));
          const trimmedValue = value.slice(0, dialogDataSourceRestrictions.MAX_USERS_COUNT); // Обрезаем массив до 10 элементов
          setValue('groups', trimmedValue); // Обновляем значение в форме

          return true;
        }

        return true;
      }),
  });

  const defaultValues = useMemo(
    () => ({
      name: source?.name || '',
      ciscoName: source?.settings.ciscoNamespace || '',
      spaceID: source?.settings.spaceObjectId ? source?.settings.spaceObjectId.toString() : '',
      groups: source?.settings.groups || [],
      emailNotifyListeners: source?.settings.emailNotifyListeners || [],
    }),
    [
      source?.name,
      source?.settings.ciscoNamespace,
      source?.settings.emailNotifyListeners,
      source?.settings.groups,
      source?.settings.spaceObjectId,
    ],
  );

  const {
    register,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    const subscription = watch(values => {
      localState.current.name = values?.name || '';
      localState.current.path = values?.spaceID || '';
      localState.current.settings.spaceObjectId = values?.spaceID || '';
      localState.current.settings.ciscoNamespace = values?.ciscoName || '';
      localState.current.settings.emailNotifyListeners = values?.emailNotifyListeners as number[];
      localState.current.settings.groups = values?.groups as number[];

      onChange(localState.current);
    });

    return () => subscription.unsubscribe();
  }, [isValid, onChange, setIsValid, watch]);

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Box width={600}>
      <FormGroup>
        {/* Channel name */}
        <FormControl variant="outlined" fullWidth>
          <TextField
            required
            key="name"
            label={t('dataSources.dialogs.name')}
            variant="outlined"
            fullWidth
            defaultValue={defaultValues.name}
            autoComplete="off"
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk, // Применяем стили для звездочки
              },
            }}
            {...register('name', { required: true })}
          />
        </FormControl>
        <Box p={2} />

        {/* Channel Type */}
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            disabled
            clearText={t('dataSources.dialogs.channelType')}
            value={'CISCO'}
            filterSelectedOptions
            renderInput={params => (
              <TextField {...params} label={t('dataSources.dialogs.channelType')} variant="outlined" />
            )}
            options={[]}
          />
        </FormControl>

        <Box p={2} />

        {/* Groups */}
        {gAPP_STORE.groups && (
          <FormControl variant="outlined" fullWidth>
            <Controller
              name="groups"
              control={control}
              rules={{ required: true }}
              defaultValue={defaultValues.groups}
              render={({ field }) => {
                const value = field.value?.map(groupId =>
                  gAPP_STORE.getGroupsStore().data.groups.find(group => group.id === groupId),
                );

                return (
                  <Autocomplete
                    value={value}
                    multiple
                    options={gAPP_STORE.getGroupsStore().data.groups}
                    noOptionsText={t('uploadForm.noGroups')}
                    getOptionLabel={option => option?.name || ''}
                    onChange={(_event, value) => field.onChange(value?.map(v => v?.id))}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    filterSelectedOptions
                    renderInput={params => (
                      <TextField
                        required
                        {...params}
                        label={t('users.groups')}
                        variant="outlined"
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk, // Применяем стили для звездочки
                          },
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip color="primary" label={option?.name} {...getTagProps({ index })} />
                      ))
                    }
                    //onChange={handleChangeGroups}
                  />
                );
              }}
            ></Controller>
          </FormControl>
        )}

        <Box p={1} />

        {/* CISCO name */}
        <FormControl variant="outlined" fullWidth>
          <TextField
            required
            key="ciscoName"
            label={t('dataSources.dialogs.ciscoName')}
            variant="outlined"
            fullWidth
            defaultValue={localState.current.settings?.ciscoNamespace || ''}
            autoComplete="off"
            error={Boolean(errors.ciscoName)}
            helperText={errors.ciscoName?.message}
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk, // Применяем стили для звездочки
              },
            }}
            {...register('ciscoName', { required: true })}
          />
        </FormControl>

        <Box p={1} />

        {/* Space ID */}
        <FormControl variant="outlined" fullWidth>
          <TextField
            required
            key="spaceID"
            label={t('dataSources.dialogs.spaceId')}
            variant="outlined"
            fullWidth
            defaultValue={localState.current.settings?.spaceObjectId || ''}
            autoComplete="off"
            error={Boolean(errors.spaceID)}
            helperText={errors.spaceID?.message}
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk, // Применяем стили для звездочки
              },
            }}
            {...register('spaceID', { required: true })}
          />
        </FormControl>

        <Box p={1} />

        {/* Email Notify */}
        {gAPP_STORE.getUsersStore().data.users && (
          <FormControl variant="outlined" fullWidth>
            <Controller
              name="emailNotifyListeners"
              control={control}
              defaultValue={defaultValues.emailNotifyListeners}
              render={({ field }) => {
                const value = field.value.map(usersId =>
                  gAPP_STORE.getUsersStore().data.users.find(users => users.id === usersId),
                );

                return (
                  <Autocomplete
                    value={value}
                    multiple
                    options={gAPP_STORE.getUsersStore().data.users}
                    clearText={t('dataSources.dialogs.emailNotify')}
                    getOptionLabel={option => option?.email || ''}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    filterSelectedOptions
                    onChange={(event, value) => field.onChange(value?.map(v => v?.id))}
                    renderInput={(params: Partial<AutocompleteRenderInputParams>) => (
                      <TextField
                        {...params}
                        required
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.asterisk, // Применяем стили для звездочки
                          },
                        }}
                        label={t('dataSources.dialogs.emailNotify')}
                        variant="outlined"
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip color="primary" label={option?.email} {...getTagProps({ index })} />
                      ))
                    }
                    //onChange={handleChangeEmailNotifyListeners}
                  />
                );
              }}
            ></Controller>
          </FormControl>
        )}
      </FormGroup>
    </Box>
  );
};
