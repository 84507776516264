import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Collapse, FormControlLabel, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { DialogStore } from '../dialog.store';

import { ItemAlgorithms } from './itemAlgorithms';
import { ItemParticipants } from './itemParticipants';
import { Stack } from './Stack';

import { gAPP_STORE } from '@/app/app-store';

export interface IItemSeparation {
  data: DialogStore;
}

export const ItemSeparation: FC<IItemSeparation> = ({ data }) => {
  const { t } = useTranslation();
  const [isSeparated, setSeparated] = useState(data.isSeparated);

  const hChangeCheckBox = useCallback(
    (value: boolean) => {
      data.setSeparated(!value);
      setSeparated(!value);
    },
    [data],
  );

  const blockIsSeperated = (
    <FormControlLabel
      label=""
      control={
        <Stack direction="row" alignItems="center" spacing={1}>
          <Checkbox checked={!isSeparated} onChange={event => hChangeCheckBox(event.target.checked)} />
          <Typography>{t('uploadForm.notseparated')}</Typography>
          <Tooltip
            title={
              <>
                <Typography>{t('uploadForm.notseparated')}</Typography>
                <Typography style={{ color: '#FFFFFFA3' }}>{t('uploadForm.notSeparatedDescription')}</Typography>
              </>
            }
          >
            <InfoIcon color="disabled" />
          </Tooltip>
        </Stack>
      }
    />
  );

  return (
    <Stack>
      {gAPP_STORE.autoSpeakerDetection ? blockIsSeperated : <div style={{ marginTop: '20px' }} />}
      <Collapse in={isSeparated}>
        <Stack spacing={2}>
          <ItemAlgorithms data={data} />
          <Box />
          <Collapse in={(gAPP_STORE.gridProxyUrl && gAPP_STORE.gridProxyUrl.length > 0) || false}>
            <ItemParticipants data={data} />
          </Collapse>
        </Stack>
      </Collapse>
    </Stack>
  );
};
