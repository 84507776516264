import { IWordsData } from '@/components/records/types';
import { BackendService } from '@/services/backend-service';

class TextService {
  getHistory = async (correlationId: string, isTranslate: boolean): Promise<IWordsData[]> => {
    const body = {
      correlationId,
      limit: 1000,
      offset: 0,
      sortOrder: 'Asc',
    };
    try {
      return await BackendService.post(`text/${isTranslate ? 'translate/' : ''}history`, JSON.stringify(body));
    } catch (error) {
      console.error('get History: ', { error });

      return [];
    }
  };
}

export const textService = new TextService();
