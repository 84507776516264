import { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import AppToolbar from 'components/app-toolbar/app-toolbar';

import { gAPP_STORE } from './app/app-store';
import { privateRoutes, publicRoutes } from './router';

require('typeface-roboto');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GuardedRoute = ({ component: Component, ...rest }: any) => {
  const auth = gAPP_STORE.loginStore.isSignedIn();

  return <Route {...rest} render={props => (auth === true ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const loadSettings = async () => {
      try {
        await gAPP_STORE.initViewSettings();
        document.title = gAPP_STORE.APP_NAME;
        await gAPP_STORE.loginStore.restoreUserSession();
        await gAPP_STORE.loadLanguages();
        await gAPP_STORE.loginStore.mfaInit();
      } catch {
        console.log('User-session is not restored.');
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  useEffect(() => {
    return reaction(
      () => gAPP_STORE.statusCodeError,
      errorCode => {
        if (errorCode === 401) {
          toast.warn(t('sessionOFF'));
          gAPP_STORE.sessionOff();
          history.push('/login');
        }
      },
    );

    // с history в зависимостях как предлагает линт разлогин не работает, поэтому дизейблим!!!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gAPP_STORE.statusCodeError]);

  return (
    <>
      <Box display="flex" flexDirection="column" style={{ height: '100vh' }}>
        {loading && (
          <Box p={5} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'row'}>
            <CircularProgress />
            <Box mr={3}></Box>
            <Typography variant="body1">{t('init')}</Typography>
          </Box>
        )}
        {!loading && (
          <>
            <AppToolbar />

            <Box display="flex" flexGrow={1} height={0}>
              <Switch>
                {privateRoutes.map(route => {
                  return (
                    <GuardedRoute path={route.path} exact={route.exact} component={route.component} key={route.path} />
                  );
                })}
                {publicRoutes.map(route => {
                  return <Route path={route.path} exact={route.exact} component={route.component} key={route.path} />;
                })}
              </Switch>
            </Box>
            <ToastContainer closeOnClick={true} pauseOnHover style={{ width: 600 }} />
          </>
        )}
      </Box>
    </>
  );
}

export default observer(App);
