import { BackendService } from './backend-service';

class RedisService {
  async putString(value: string): Promise<string> {
    try {
      return await BackendService.post('redis/string/set', value, false, true);
    } catch (err) {
      console.error('error in RedisService:', err);

      return '';
    }
  }

  async getString(value: string): Promise<string> {
    try {
      return await BackendService.get(`redis/string/get?key=${value}`, undefined, false, false, undefined, false, true);
    } catch (err) {
      console.error('error in RedisService:', err);

      return '';
    }
  }
}

export const redisService = new RedisService();
