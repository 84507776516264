import { DataGridStore, EDataGridStoreSortMode, IDataGridStoreData, IDataGridStoreState } from '@uk';
import { action, computed, makeAutoObservable, runInAction } from 'mobx';

import { BackendService } from 'services';

import i18n from 'i18next';

import { cloneDeep } from 'lodash';

import {
  ERequestFilterPredicate,
  IFilterData,
  IFilterJsonField,
  IRequestFilterData,
  IRequestFilterField,
  TFilterDataFields,
} from '../filter/i-filter';
import {
  addDateField,
  addTextField,
  addTextFieldJsonField,
  addTopicTypeFieldJsonField,
  convertPredicate,
} from '../records/utils/filterDataToRequest';

import { addActionErrorField, addActionTypeField } from './event-log-filter-utils';
import { EventLogService } from './event-log-service';
import { eventLogGridDescriptor } from './event-log.grid.descriptor';

import { LoggedDatetime, MessageEventLog, UserEventLog } from './dataEcxelUtils';
import { EEventType, EventTypeMap, IEventDescription, IEventLogEntry, IExportEvent } from './types';

import { DataGridSettingsStore } from '@/react-ui-kit/src/components/dataGrid/DataGridSettings.store';

interface IRecordsData {
  records: IEventLogEntry[];
  hasNextPage: boolean;
}

const recordsDataInitialState: IRecordsData = {
  records: [],
  hasNextPage: false,
};

export class EventLogStore {
  data: IRecordsData = recordsDataInitialState;

  gridStore: DataGridStore<IEventLogEntry>;
  gridCols = eventLogGridDescriptor;

  eventData: IExportEvent[] = [];

  filterData: IFilterData = {};
  gridSettingsStore: DataGridSettingsStore | undefined;

  listEventsLoaded = false;
  eventDescriptions: IEventDescription[] = [];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.gridStore = new DataGridStore<IEventLogEntry>('EventLog', this.gridCols, {
      dataProvider: this.dataProvider,
      //dataProvider: this.dataProviderMock,
    });
  }

  @action
  async init(): Promise<void> {
    this.eventDescriptions = await EventLogService.getEventDecriptions();
    this.listEventsLoaded = true;
  }

  @computed getEventListByTypus(typus?: EEventType): IEventDescription[] {
    return typus === undefined ? this.eventDescriptions : this.eventDescriptions.filter(ev => ev.eventType === typus);
  }

  @computed getFullActionTypeList(): string[] {
    return this.eventDescriptions.map(ev => ev.actionMessage.split(' ', 1)[0]);
  }

  @computed getEventDescription(actionId: number) {
    if (actionId < 0 || actionId >= this.eventDescriptions.length) return '';

    return this.eventDescriptions[actionId].actionMessage.split(' ', 1)[0];
  }

  @computed getParametres(actionIds: string[]): string[] {
    const params = new Set<string>();
    actionIds.forEach(id => {
      this.eventDescriptions[+id].actionParameters.forEach(p => {
        params.add(p);
      });
    });

    return Array.from(params);
  }

  dataProvider = async (state: IDataGridStoreState): Promise<IDataGridStoreData<IEventLogEntry>> => {
    await this.getAll(state.offset, state.limit);

    return { data: this.data.records, total: this.data.records.length, hasNextPage: this.data.hasNextPage };
  };

  setFilterData(filterData: IFilterData) {
    this.filterData = filterData;
  }

  onFilterFieldChange(fieldName: keyof IFilterData) {
    runInAction(() => {
      this.filterData[fieldName] = cloneDeep(this.filterData[fieldName]);
    });
  }

  private getAll = async (offset = 0, limit = 0) => {
    const result = await this.getRecordsData(offset, limit);

    runInAction(() => {
      if (result) {
        this.data.records = result.data;
        this.data.hasNextPage = result.hasNextPage;
      } else {
        this.data.records = [];
      }
    });
  };

  filterDataToRequestFields(filterData: IFilterData): IRequestFilterField<TFilterDataFields>[] {
    const fields: IRequestFilterField<TFilterDataFields>[] = [];

    addActionErrorField<TFilterDataFields>(
      (filterData.eventType?.value as string[]) || [],
      'eventType',
      convertPredicate(filterData.eventType?.predicate),
      fields,
    );

    addActionTypeField<TFilterDataFields>(
      (filterData.actionType?.value as string[]) || [],
      'actionType',
      convertPredicate(filterData.actionType?.predicate),
      fields,
    );

    // Date loadFrom
    addDateField<TFilterDataFields>(
      filterData.loadFrom?.value?.toString() || '',
      'loggedDatetime',
      ERequestFilterPredicate.EQ_GR,
      fields,
    );
    // Date loadTo
    addDateField<TFilterDataFields>(
      filterData.loadTo?.value?.toString() || '',
      'loggedDatetime',
      ERequestFilterPredicate.EQ_LESS,
      fields,
    );

    // userAccount
    addTextField<TFilterDataFields>(
      (filterData.userId?.value as string[]) || [],
      'userId',
      convertPredicate(filterData.userId?.predicate),
      fields,
    );

    /**
     *
     *
     *
     */

    return fields;
  }

  filterDataToRequestJsonFields(filterData: IFilterData): IFilterJsonField<TFilterDataFields>[] {
    const jsonFields: IFilterJsonField<TFilterDataFields>[] = [];
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.login?.value as string[]) || [],
      'login',
      convertPredicate(filterData.login?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.topic_name?.value as string[]) || [],
      'topic_name',
      convertPredicate(filterData.topic_name?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.format?.value as string[]) || [],
      'format',
      convertPredicate(filterData.format?.predicate),
      jsonFields,
    );
    addTopicTypeFieldJsonField<TFilterDataFields>(
      (filterData.topic_type?.value as string[]) || [],
      convertPredicate(filterData.topic_name?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.group_name?.value as string[]) || [],
      'group_name',
      convertPredicate(filterData.group_name?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.groups_names?.value as string[]) || [],
      'groups_names',
      convertPredicate(filterData.group_name?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.template_name?.value as string[]) || [],
      'template_name',
      convertPredicate(filterData.template_name?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.record_name?.value as string[]) || [],
      'record_name',
      convertPredicate(filterData.record_name?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.dictor_name?.value as string[]) || [],
      'dictor_name',
      convertPredicate(filterData.dictor_name?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.dictor_surname?.value as string[]) || [],
      'dictor_surname',
      convertPredicate(filterData.dictor_surname?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.filename?.value as string[]) || [],
      'filename',
      convertPredicate(filterData.filename?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.channel_name?.value as string[]) || [],
      'channel_name',
      convertPredicate(filterData.channel_name?.predicate),
      jsonFields,
    );
    addTextFieldJsonField<TFilterDataFields>(
      (filterData.processing_method?.value as string[]) || [],
      'processing_method',
      convertPredicate(filterData.processing_method?.predicate),
      jsonFields,
    );

    return jsonFields;
  }

  private createRequestFilter = (offset: number, limit: number): IRequestFilterData<TFilterDataFields> => {
    let sort: EDataGridStoreSortMode | undefined = EDataGridStoreSortMode.DSC;

    if (this.gridStore) {
      sort = this.gridStore.gridSettingsStore.colsLayout.find(item => item.column.id === 'loggedDatetime')?.sort;
    }

    // if (this.gridStore) {
    //   sort = this.gridStore.gridSettingsStore.colsLayout.find(item => item.column.id === 'loggedDatetime')?.sort;
    // }

    const filterFields = this.filterDataToRequestFields(this.filterData);
    const jsonFields = this.filterDataToRequestJsonFields(this.filterData);

    return {
      fields: filterFields,
      jsonFields: jsonFields,
      limit,
      offset,
      sortByField: {
        name: 'loggedDatetime',
        order: sort === 0 ? 'Asc' : 'Dsc',
      },
    };
  };

  getEventLogWithoutLimit = async () => {
    const filter = this.createRequestFilter(0, 5000);
    const resp = await BackendService.post('event_log/filter', JSON.stringify(filter));

    this.eventData = resp.map((item: IEventLogEntry) => {
      return {
        eventType: i18n.t(`eventType.${EventTypeMap.get(item.eventType)}` || ''),
        actionType: i18n.t(`actionType.${this.getEventDescription(item.actionType)}`),
        user: UserEventLog(item),
        ip: item.ip ?? '--',
        loggedDatetime: LoggedDatetime(item.loggedDatetime as string),
        message: MessageEventLog(item.actionType, item.actionParameters),
      };
    });
  };

  private getRecordsData = async (
    offset = 0,
    limit = 0,
  ): Promise<{
    data: IEventLogEntry[];
    hasNextPage: boolean;
  } | null> => {
    if (limit === 0) {
      return null;
    }
    try {
      const records: IEventLogEntry[] = [];
      const filter = this.createRequestFilter(offset, limit + 1);
      const resp = await BackendService.post('event_log/filter', JSON.stringify(filter));
      records.push(...(resp || []));

      return { data: records.slice(0, limit), hasNextPage: records.length > limit };
    } catch (ex) {
      console.error('Error loading records, ex = ', ex);

      return null;
    }
  };

  reload = async () => {
    this.gridStore.resetSelected();
    await this.gridStore.setCurrentPage(0);
    //await this.gridStore.reload();
  };
}
