import { BackendService } from '../backend-service';

import { IUser } from '@/components/users/i-user';

class _UserService {
  async getUserDataViaJWT(token: string) {
    return await BackendService.get(`user/jwt/auth?token=${token}`, undefined, false);
  }

  async getCurrentUserData(): Promise<IUser> {
    return await BackendService.get('user/my-info', undefined, false);
  }

  async generateJWTToken(): Promise<string> {
    return await BackendService.get('user/jwt/generate', undefined, false);
  }
}

export const UserService = new _UserService();
