import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, MenuItem, TextField, Typography, useTheme } from '@material-ui/core';
//import { Autocomplete, FilterOptionsState } from '@material-ui/lab';

import { ILabel } from '../../types';
import { Stack } from '../UploadFiles/items/Stack';

import { LabelChip } from './LabelChip';

import { labelService } from '@/services/labels/labels-service';

export interface IItemGridLabelsComboBox {
  selectedTypeIds?: string[];
  defaultLabel?: ILabel;
  setLabel?: (label: ILabel | undefined) => void;
}

export const ItemLabelTypesComboBox = ({ defaultLabel, selectedTypeIds, setLabel }: IItemGridLabelsComboBox) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [labelTypes, setLabelTypes] = useState<ILabel[]>([]);

  useEffect(() => {
    const initLabels = async () => {
      const result = await labelService.getLabelTypes();

      setLabelTypes(result.filter(label => !label.isDeleted));
    };

    void initLabels();
  }, []);

  const menuList = useMemo(
    () => (selectedTypeIds ? labelTypes.filter(label => !selectedTypeIds.includes(label.typeId)) : []),
    [labelTypes, selectedTypeIds],
  );

  const labelOfLabel = useMemo(
    () => <Typography data-autotest="ItemLabelTypesComboBoxLabel">{t('labelForm.labelName')}</Typography>,
    [t],
  );

  const hChange = useCallback(
    (value: string) => {
      if (setLabel) {
        setLabel(menuList[+value]);
      }
    },
    [setLabel, menuList],
  );

  const blockLabelMenu = useMemo(() => {
    return (
      <TextField
        data-autotest="ItemlabelTypesField"
        variant="filled"
        fullWidth
        label={t('labelForm.labelNameTips')}
        select
        onChange={event => {
          hChange(event.target.value);
        }}
        value={defaultLabel ? menuList.findIndex(lbl => lbl.typeId === defaultLabel.typeId) : -1}
      >
        {menuList.map((label: ILabel, index: number) => (
          <MenuItem key={index} value={index} data-autotest="ItemLabelTypesMenu">
            <LabelChip label={label} />
          </MenuItem>
        ))}
      </TextField>
    );
  }, [t, defaultLabel, menuList, hChange]);

  const blockLabelRO = useMemo(() => {
    return setLabel ? null : defaultLabel ? (
      <FormControl variant="outlined" fullWidth>
        <Stack spacing={0}>
          <Stack
            flexWrap="wrap"
            direction="row"
            alignItems={'center'}
            p={1}
            border={`1px solid ${theme.palette.grey[200]}`}
            borderRadius={1}
            minHeight={'2.2em'}
            height={'52px'}
            gap={0.5}
          >
            <LabelChip
              label={{
                id: defaultLabel.id,
                typeId: defaultLabel.typeId,
                name: defaultLabel.name,
                color: defaultLabel.color,
              }}
            />
          </Stack>
        </Stack>
      </FormControl>
    ) : null;
  }, [defaultLabel, setLabel, theme.palette.grey]);

  return (
    <Stack spacing={1} mb={2} data-autotest="ItemLabelTypesComboBox">
      {labelOfLabel}
      {setLabel ? blockLabelMenu : blockLabelRO}
    </Stack>
  );
};
