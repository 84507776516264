import { FC, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Box, CircularProgress, Tooltip, Typography, useTheme } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';

import { DialogStore } from '../dialog.store';

import { Stack } from './Stack';
import { StyledIconButton } from './StyledIconButton';

import { gridProxyService } from '@/services/grid-proxy/grid-proxy-service';
import HotlistChip from '@/components/records/HotlistChip';
import { gAPP_STORE } from '@/app/app-store';
import { dialogUploadRecordsRestrictions } from '@/common/constants';

export interface IItemHotlists {
  data: DialogStore;
}

export const ItemHotlists: FC<IItemHotlists> = observer(({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const handleBtn = useCallback(async () => {
    const limit = data.maxHotlists - data.hotlists.length;
    if (limit <= 0) {
      toast.info(t('uploadForm.hotlistsLimitExceed'));

      return;
    }
    setLoading(true);
    const dlgFieldValues = await data.saveToUploadResult();

    const result = await gridProxyService.searchHotlists(limit, dlgFieldValues);
    if (result === undefined) setLoading(false);
  }, [data, t]);

  const labelAndButton = useMemo(() => {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography>{t('uploadForm.hotlists')}</Typography>
        <Tooltip
          title={
            <>
              <Typography>{t('uploadForm.hotlists')}</Typography>
              <Typography style={{ color: '#FFFFFFA3' }}>{t('uploadForm.hotlistsDescription')}</Typography>
            </>
          }
        >
          <InfoIcon color="disabled" />
        </Tooltip>

        <StyledIconButton
          size="small"
          style={{ color: `${data.isAllFileLoaded ? 'rgb(11, 43, 72)' : 'red'}` }}
          onClick={data.isAllFileLoaded ? handleBtn : undefined}
        >
          <Add />
        </StyledIconButton>
        {!data.isAllFileLoaded ? <Typography color="error">{t('uploadForm.filesIsLoadingHotlist')}</Typography> : <></>}
      </Stack>
    );
  }, [data.isAllFileLoaded, handleBtn, t]);

  const handleExclude = useCallback(
    (index: number) => {
      data.excludeHotlist(index);
    },
    [data],
  );

  const listHotlists = useMemo(() => {
    return (
      <Stack
        flexWrap="wrap"
        direction="row"
        alignItems={'center'}
        p={1}
        border={`1px solid ${theme.palette.grey[200]}`}
        borderRadius={1}
        minHeight={'2.2em'}
        gap={0.5}
      >
        {data.hotlists.map((hotlist, index) => (
          <HotlistChip
            hotlist={hotlist}
            index={index}
            onDelete={handleExclude}
            maxLength={dialogUploadRecordsRestrictions.MAX_VISIBLE_HOTLIST_LENGTH}
          />
        ))}
      </Stack>
    );
  }, [data.hotlists, handleExclude, theme.palette.grey]);

  if (loading) {
    return (
      <>
        <Box p={5} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'row'}>
          <CircularProgress />
          <Box mr={3}></Box>
          <Typography variant="body1">{t('uploadForm.hotlistsLoading')}</Typography>
        </Box>
      </>
    );
  }

  if (gAPP_STORE.gridProxyUrl === undefined || gAPP_STORE.gridProxyUrl.length === 0) {
    return null;
  }

  return (
    <Stack spacing={0}>
      {labelAndButton}
      {listHotlists}
    </Stack>
  );
});
