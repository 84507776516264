import { Box, makeStyles, Tooltip } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import { observer } from 'mobx-react';

import { IRecord } from './types';

import { gAPP_STORE } from '@/app/app-store';
const useStyles = makeStyles(() => ({
  tooltipContent: {
    gap: '4px',
  },
  divStyle: {
    height: '19px',
    padding: '1px 4px',
    border: '2px solid rgba(17, 25, 39, 1)',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
  },
  tooltipContentRtl1: {
    direction: 'rtl',
  },
}));

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(17, 25, 39, 1)',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
    borderRadius: '4px',
    padding: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    lineHeight: '18.75px',
  },
}))(Tooltip);

interface ITitleProps {
  tmp?: string[] | string;
  isRTL: boolean;
  loaded: boolean;
}

const Title = (props: ITitleProps) => {
  const { tmp, isRTL, loaded } = props;
  const isList = Array.isArray(tmp);
  const styles = useStyles();
  const { t } = useTranslation();

  if (!loaded) return <>{t('summary.topicLoading')}</>;

  return (
    <div className={clsx(styles.tooltipContent, { [styles.tooltipContentRtl1]: isRTL })}>
      {isList && (
        <>
          {tmp.map((v, index, array) => (
            <li key={`topicsItem-${index}`}>
              {v}
              {index === array.length - 1 ? '.' : isRTL ? <span>&#8271;</span> : ';'}
            </li>
          ))}
        </>
      )}

      {!isList && tmp}
    </div>
  );
};

interface ISummaryTopicCountLabel {
  count: number;
  record: IRecord;
}

export type TipsCacheType = { [recordId: number]: boolean };

const SummaryTopicCountLabel: React.FC<ISummaryTopicCountLabel> = observer(({ count, record }) => {
  const { t } = useTranslation();
  const recordLanguage = record.languageResolved;
  const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);

  const styles = useStyles();
  const recordsStore = useMemo(() => gAPP_STORE.getRecordsStore(), []);
  const { loadSummaryTopicForRecord } = recordsStore;
  const topicList = recordsStore.getTopicList(record.id);
  const [topicsLoaded, setTopicsLoaded] = useState(recordsStore.mapSummaryTopic.has(record.id));
  const hOpen = useCallback(() => {
    const getSummaryTopics = async () => {
      try {
        await loadSummaryTopicForRecord(record.id);
        setTopicsLoaded(true);
      } catch (error) {
        console.log('from summaryService ', { recordId: record.id, error });
      }
    };

    if (!topicsLoaded) {
      void getSummaryTopics();
    }
  }, [loadSummaryTopicForRecord, record.id, topicsLoaded]);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {count === 0 ? (
          <div className={styles.divStyle}>{'0'}</div>
        ) : (
          <StyledTooltip onOpen={() => hOpen()} title={<Title tmp={topicList} isRTL={isRTL} loaded={topicsLoaded} />}>
            <div className={styles.divStyle}>{count}</div>
          </StyledTooltip>
        )}
      </Box>
    </>
  );
});

export default SummaryTopicCountLabel;
