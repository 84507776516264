import { Subtitles, Translate } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { Tooltip } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { ukColors } from '@/react-ui-kit/src';

const selectedStyle = { background: ukColors.primary, color: 'white', borderRadius: '10px' };
interface ITranscriptTranslateToggleProps {
  onChange: (isTranslate: boolean) => void;
  isTranslate: boolean;
}

export const TranscriptTranslateToggle = (props: ITranscriptTranslateToggleProps) => {
  const { onChange, isTranslate } = props;
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      size="small"
      value={isTranslate}
      exclusive
      onChange={(_, v) => onChange(v)}
      style={{
        border: '1px solid gray',
        borderRadius: '10px',
        borderLeft: isTranslate ? '1px solid gray' : 0,
        borderRight: isTranslate ? 0 : '1px solid gray',
      }}
    >
      <Tooltip title={t('records.transcription')}>
        <ToggleButton
          size="small"
          type="button"
          value={false}
          style={{ color: ukColors.primary, border: 'none', ...(!isTranslate && selectedStyle) }}
        >
          <Subtitles />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={t('records.translate')}>
        <ToggleButton
          size="small"
          value={true}
          style={{ color: ukColors.primary, border: 'none', ...(isTranslate && selectedStyle) }}
        >
          <Translate />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};
