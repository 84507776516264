//import { useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
//import { makeStyles } from '@material-ui/core';
import { LoadingPanel } from '@uk';

import { BriefPanel } from './BriefPanel';
import { SummaryHistory } from './SummaryHistory';

import { gAPP_STORE } from '@/app/app-store';
import { DecisionsPanel } from '@/components/summary/DecisionsPanel';
import { ProposalPanel } from '@/components/summary/ProposalPanel';

function tabProps(name: string, isActive: boolean) {
  return {
    id: `simple-${name}`,
    'aria-controls': `tabpanel-${name}`,
    style: {
      height: '48px',
      backgroundColor: 'white',
      color: `${isActive ? 'rgba(11, 43, 72, 1)' : 'rgba(0, 0, 0, 0.56)'}`,
      fontSize: '14px',
      fontWeight: `${isActive ? 600 : 400}`,
      boxShadow: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      borderBottom: isActive ? '2px solid rgba(11, 43, 72, 1)' : 'none',
    },
  };
}

export const MainDataPanel = observer(({ className }: { className: string }) => {
  const { t } = useTranslation();
  // const classes = useStyles();
  // const textRulerRef = useRef<HTMLDivElement>(null);
  // const textDivRef = useRef<HTMLDivElement>(null);
  const { showSumDecisions, showSumInstructions, summaryStore } = gAPP_STORE;
  const { allTopicsReady, loadedTabs } = summaryStore;

  const [currentScrollTop, setCurrentScrollTop] = useState(0);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const showHistory = summaryStore.showHistory;

  const panelHistory = showHistory ? <SummaryHistory disabled={summaryStore.isTranslate} /> : <></>;

  const summaryTabs = useMemo(
    () => (
      <AppBar position="static" style={{ backgroundColor: 'white', width: '100%' }}>
        <Tabs
          value={summaryStore}
          onChange={(event, value) => summaryStore.setActiveTab(value)}
          aria-label="summary tabs"
          style={{ backgroundColor: 'white' }}
        >
          <Tab
            data-autotest={'summary-tab1'}
            label={t('summary.tab1')}
            value={'tab1'}
            {...tabProps('tab1', summaryStore.isActiveTab('tab1'))}
          />
          {showSumDecisions && (
            <Tab
              data-autotest={'summary-tab2'}
              label={t('summary.tab2')}
              value={'tab2'}
              {...tabProps('tab2', summaryStore.isActiveTab('tab2'))}
            />
          )}
          {showSumInstructions && (
            <Tab
              data-autotest={'summary-tab3'}
              label={t('summary.tab3')}
              value={'tab3'}
              {...tabProps('tab3', summaryStore.isActiveTab('tab3'))}
            />
          )}
        </Tabs>
      </AppBar>
    ),
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [summaryStore.activeTab, t, showSumDecisions, showSumInstructions],
  );

  const summaryCurrentPanel: JSX.Element = useMemo(() => {
    switch (summaryStore.activeTab) {
      case 'tab1':
        return <BriefPanel />;
      case 'tab2':
        return <DecisionsPanel />;
      case 'tab3':
        return <ProposalPanel />;
      default:
        return <></>;
    }
  }, [summaryStore.activeTab]);

  const PanelLoading = useMemo(
    () => (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <LoadingPanel />
      </div>
    ),
    [],
  );

  useEffect(() => {
    if (summaryStore.summaryHistoryReady || allTopicsReady || loadedTabs.length) {
      setTimeout(() => {
        if (scrollContainerRef.current && currentScrollTop) {
          scrollContainerRef.current.scrollTop = currentScrollTop;
        }
      }, 0);
    }
  }, [allTopicsReady, currentScrollTop, loadedTabs.length, summaryStore.summaryHistoryReady]);

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    scrollContainerRef.current && setCurrentScrollTop(scrollContainerRef.current.scrollTop);
  };

  return (
    <div data-autotest={'MainSummaryPanel'} id="E" className={className}>
      {summaryTabs}
      <div id="D" className={className}>
        {/* place of history - begin */}
        {/* <div className={classes.estContainer}>
          <div className={classes.est}>
            <div ref={textRulerRef} className={classes.phrase}></div>
            {showHistory && <div style={{ width: '45px', minWidth: '45px', maxWidth: '45px' }}></div>}
          </div>
        </div> */}
        {/* place of history - end */}

        <div id="C" style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          {!summaryStore.summaryHistoryReady && PanelLoading}
          {summaryStore.summaryHistoryReady && (
            <div id="B" className={className} style={{ overflowY: 'hidden' }}>
              <div id="A" className={className} style={{ overflowY: 'hidden', height: 0 }}>
                <div
                  ref={scrollContainerRef}
                  onScroll={onScroll}
                  style={{ overflowY: 'scroll', flexGrow: 1, overflowAnchor: 'none' }}
                >
                  {summaryCurrentPanel}
                </div>
              </div>
            </div>
          )}

          {panelHistory}
        </div>
      </div>
    </div>
  );
});
