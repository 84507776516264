import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormGroup, Box } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';

import { CommonDialog, CommonDialogUiStore, ECommonDialogCloseStatus } from '@uk';

import { CGroup, IGroup } from './types';

import { groupSettingsRestrictions } from '@/common/constants';

export interface IGroupDialogResult {
  group: IGroup;
  oldValue?: IGroup;
  closeStatus: ECommonDialogCloseStatus;
}

export interface IDeleteDialogResult {
  closeStatus: ECommonDialogCloseStatus;
}

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

interface IGroupDialogProps {
  group: IGroup | undefined;
  onClose: (dialogResult: IGroupDialogResult) => void;
}

const GroupDialog: React.FC<IGroupDialogProps> = ({ group, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());

  const checkNameError = (name: string) => {
    if (
      name.length < groupSettingsRestrictions.MIN_NAME_LENGTH ||
      name.length > groupSettingsRestrictions.MAX_NAME_LENGTH
    ) {
      return true;
    }
    if (name.trim() !== name) {
      return true;
    }

    return false;
  };

  const [isError, setError] = useState(false);
  const [helperText, setHelperText] = useState<string | null>(null);

  const dialogResult: IGroupDialogResult = useMemo(
    () => ({ group: group ? { ...group } : new CGroup(), closeStatus: 0, oldValue: group }),
    // eslint-disable-next-line
    [group, open],
  );

  userDlgUiStoreRef.current.setOkEnabled(
    isError ? false : dialogResult.group ? !checkNameError(dialogResult.group.name) : false,
  );

  const handleClose = (status: number) => {
    if (status === ECommonDialogCloseStatus.CANCEL || status === ECommonDialogCloseStatus.OK) {
      dialogResult.closeStatus = status;
      onClose(dialogResult);
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const name: string = event.target.value;
    const trimmedName = name.trim();

    const hasLeadingOrTrailingSpaces = name !== trimmedName;
    const isError = checkNameError(name);

    setError(isError || hasLeadingOrTrailingSpaces);
    setHelperText(hasLeadingOrTrailingSpaces ? t('users.trimError') : isError ? t('users.lengthError') : null);

    if (!isError && !hasLeadingOrTrailingSpaces) {
      dialogResult.group.name = trimmedName;
      userDlgUiStoreRef.current.setOkEnabled(true);
    } else {
      userDlgUiStoreRef.current.setOkEnabled(false);
    }
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dialogResult.group.description = event.target.value;
  };

  const DialogBody = () => {
    return (
      <Box width={600}>
        <FormGroup>
          <>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                key="name"
                label={t('groups.name')}
                variant="outlined"
                fullWidth
                defaultValue={dialogResult.group.name}
                onChange={handleNameChange}
                autoComplete="off"
                error={isError}
                inputProps={{ maxLength: groupSettingsRestrictions.MAX_NAME_LENGTH }}
                helperText={helperText}
              />
            </FormControl>
            <Box p={2} />

            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                key="description"
                label={t('groups.description')}
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                inputProps={{ maxLength: groupSettingsRestrictions.MAX_DESCRIPTION_LENGTH }}
                defaultValue={dialogResult.group.description}
                onChange={handleDescriptionChange}
                autoComplete="off"
              />
            </FormControl>
          </>
        </FormGroup>
      </Box>
    );
  };

  return (
    <CommonDialog
      title={t('group')}
      open={true}
      onClose={handleClose}
      contentComponent={DialogBody}
      autotestId={'groups'}
      uiStore={userDlgUiStoreRef.current}
    />
  );
};

export default GroupDialog;
